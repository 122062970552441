export default {
  'pages.layouts.userLayout.title': 'Ant Design 是西湖区最具影响力的 Web 设计规范',
  'pages.login.accountLogin.tab': '账户密码登录',
  'pages.login.accountLogin.errorMessage': '错误的用户名和密码（admin/ant.design)',
  'pages.login.username.placeholder': '用户名: admin or user',
  'pages.login.username.required': '用户名是必填项！',
  'pages.login.password.placeholder': '密码: ant.design',
  'pages.login.password.required': '密码是必填项！',
  'pages.login.phoneLogin.tab': '手机号登录',
  'pages.login.phoneLogin.errorMessage': '验证码错误',
  'pages.login.phoneNumber.placeholder': '请输入手机号！',
  'pages.login.phoneNumber.required': '手机号是必填项！',
  'pages.login.phoneNumber.invalid': '不合法的手机号！',
  'pages.login.captcha.placeholder': '请输入验证码！',
  'pages.login.captcha.required': '验证码是必填项！',
  'pages.login.phoneLogin.getVerificationCode': '获取验证码',
  'pages.getCaptchaSecondText': '秒后重新获取',
  'pages.login.rememberMe': '自动登录',
  'pages.login.forgotPassword': '忘记密码 ?',
  'pages.login.submit': '登录',
  'pages.login.loginWith': '其他登录方式 :',
  'pages.login.registerAccount': '注册账户',
  'pages.welcome.advancedComponent': '高级表格',
  'pages.welcome.link': '欢迎使用',
  'pages.welcome.advancedLayout': '高级布局',
  'pages.welcome.alertMessage': '更快更强的重型组件，已经发布。',
  'pages.admin.subPage.title': ' 这个页面只有 admin 权限才能查看',
  'pages.admin.subPage.alertMessage': 'umi ui 现已发布，欢迎使用 npm run ui 启动体验。',
  'pages.searchTable.createForm.newRule': '新建规则',
  'pages.searchTable.updateForm.ruleConfig': '规则配置',
  'pages.searchTable.updateForm.basicConfig': '基本信息',
  'pages.searchTable.updateForm.ruleName.nameLabel': '规则名称',
  'pages.searchTable.updateForm.ruleName.nameRules': '请输入规则名称！',
  'pages.searchTable.updateForm.ruleDesc.descLabel': '规则描述',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': '请输入至少五个字符',
  'pages.searchTable.updateForm.ruleDesc.descRules': '请输入至少五个字符的规则描述！',
  'pages.searchTable.updateForm.ruleProps.title': '配置规则属性',
  'pages.searchTable.updateForm.object': '监控对象',
  'pages.searchTable.updateForm.ruleProps.templateLabel': '规则模板',
  'pages.searchTable.updateForm.ruleProps.typeLabel': '规则类型',
  'pages.searchTable.updateForm.schedulingPeriod.title': '设定调度周期',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': '开始时间',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': '请选择开始时间！',
  'pages.searchTable.titleDesc': '描述',
  'pages.searchTable.ruleName': '规则名称为必填项',
  'pages.searchTable.titleCallNo': '服务调用次数',
  'pages.searchTable.titleStatus': '状态',
  'pages.searchTable.nameStatus.default': '关闭',
  'pages.searchTable.nameStatus.running': '运行中',
  'pages.searchTable.nameStatus.online': '已上线',
  'pages.searchTable.nameStatus.abnormal': '异常',
  'pages.searchTable.titleUpdatedAt': '上次调度时间',
  'pages.searchTable.exception': '请输入异常原因！',
  'pages.searchTable.titleOption': '操作',
  'pages.searchTable.config': '配置',
  'pages.searchTable.subscribeAlert': '订阅警报',
  'pages.searchTable.title': '查询表格',
  'pages.searchTable.new': '新建',
  'pages.searchTable.chosen': '已选择',
  'pages.searchTable.item': '项',
  'pages.searchTable.totalServiceCalls': '服务调用次数总计',
  'pages.searchTable.tenThousand': '万',
  'pages.searchTable.batchDeletion': '批量删除',
  'pages.searchTable.batchApproval': '批量审批',
  'page.baseinfo.bankmanage.column.title.action': '操作',
  'page.baseinfo.bankmanage.column.title.english_name': '英文名称',
  'page.baseinfo.bankmanage.column.title.id': 'ID',
  'page.baseinfo.bankmanage.column.title.index': '序号',
  'page.baseinfo.bankmanage.column.title.is_active': '是否可用',
  'page.baseinfo.bankmanage.column.title.name': '姓名',
  'page.baseinfo.bankmanage.column.title.swift_code': 'swift_code',
  'page.baseinfo.bankmanage.column.title.update_time': '更新时间',
  'page.baseinfo.bankmanage.form.label.english_name': '英文名称',
  'page.baseinfo.bankmanage.form.label.is_active': '是否可用',
  'page.baseinfo.bankmanage.form.label.name': '名称',
  'page.baseinfo.paymentmethod.form.label.search_text': '搜索文本',
  'page.baseinfo.paymentmethod.form.placeholder.search_text': '请填写搜索文本',
  'page.baseinfo.bankmanage.form.label.swift_code': 'swift_code',
  'page.baseinfo.bankmanage.form.placeholder.english_name': '请填写英文名称',
  'page.baseinfo.bankmanage.form.placeholder.is_active': '请选择是否可用',
  'page.baseinfo.bankmanage.form.placeholder.name': '请填写名称',
  'page.baseinfo.bankmanage.form.placeholder.swift_code': '请填写swift_code',
  'page.baseinfo.ccbankaccountscc.column.title.account_name': '账户名',
  'page.baseinfo.ccbankaccountscc.column.title.account_number': '账号',
  'page.baseinfo.ccbankaccountscc.column.title.action': '操作',
  'page.baseinfo.ccbankaccountscc.column.title.create_time': '创建时间',
  'page.baseinfo.ccbankaccountscc.column.title.id': 'ID',
  'page.baseinfo.ccbankaccountscc.column.title.index': '序号',
  'page.baseinfo.ccbankaccountscc.column.title.is_active': '是否可用',
  'page.baseinfo.ccbankaccountscc.column.title.update_time': '更新时间',
  'page.baseinfo.ccbankaccountscc.form.label.account_name': '账户名',
  'page.baseinfo.ccbankaccountscc.form.label.account_number': '账号',
  'page.baseinfo.ccbankaccountscc.form.placeholder.account_name': '请填写账户名',
  'page.baseinfo.ccbankaccountscc.form.placeholder.account_number': '请填写账号',
  'page.baseinfo.cccompany.column.title.action': '操作',
  'page.baseinfo.cccompany.column.title.address': '地址',
  'page.baseinfo.cccompany.column.title.contact_number': '联系方式',
  'page.baseinfo.cccompany.column.title.country__name': '国家',
  'page.baseinfo.cccompany.column.title.id': 'ID',
  'page.baseinfo.cccompany.column.title.index': '序号',
  'page.baseinfo.cccompany.column.title.is_active': '是否可用',
  'page.baseinfo.cccompany.column.title.name': '公司名称',
  'page.baseinfo.cccompany.column.title.abbr': '公司缩写',
  'page.baseinfo.cccompany.column.title.update_time': '更新时间',
  'page.baseinfo.cccompany.form.label.address': '地址',
  'page.baseinfo.cccompany.form.label.contact_number': '联系方式',
  'page.baseinfo.cccompany.form.label.country_id': '国家',
  'page.baseinfo.cccompany.form.label.is_active': '是否可用',
  'page.baseinfo.cccompany.form.label.name': '公司名称',
  'page.baseinfo.cccompany.form.label.abbr': '公司缩写',
  'page.baseinfo.cccompany.form.placeholder.address': '请填写地址',
  'page.baseinfo.cccompany.form.placeholder.contact_number': '请填写联系方式',
  'page.baseinfo.cccompany.form.placeholder.country_id': '请选择国家',
  'page.baseinfo.cccompany.form.placeholder.is_active': '请选择是否可用',
  'page.baseinfo.cccompany.form.placeholder.name': '请填写账户名',
  'page.baseinfo.cccompany.form.placeholder.abbr': '请填写公司缩写',
  'page.baseinfo.ccemail.column.title.action': '操作',
  'page.baseinfo.ccemail.column.title.create_time': '创建时间',
  'page.baseinfo.ccemail.column.title.email': '邮箱地址',
  'page.baseinfo.ccemail.column.title.id': 'ID',
  'page.baseinfo.ccemail.column.title.index': '序号',
  'page.baseinfo.ccemail.column.title.is_active': '是否可用',
  'page.baseinfo.ccemail.column.title.name': '账号',
  'page.baseinfo.ccemail.column.title.password': '邮箱密码',
  'page.baseinfo.ccemail.column.title.port': '服务器端口号',
  'page.baseinfo.ccemail.column.title.smtp': '邮箱服务器',
  'page.baseinfo.ccemail.column.title.update_time': '更新时间',
  'page.baseinfo.ccemail.form.label.email': '邮箱地址',
  'page.baseinfo.ccemail.form.label.is_active': '是否可用',
  'page.baseinfo.ccemail.form.label.name': '账号',
  'page.baseinfo.ccemail.form.label.password': '邮箱密码',
  'page.baseinfo.ccemail.form.label.port': '服务器端口号',
  'page.baseinfo.ccemail.form.label.smtp': '邮箱服务器',
  'page.baseinfo.ccemail.form.placeholder.email': '请填写邮箱地址',
  'page.baseinfo.ccemail.form.placeholder.is_active': '请选择状态',
  'page.baseinfo.ccemail.form.placeholder.name': '请填写账号',
  'page.baseinfo.ccemail.form.placeholder.password': '请填写邮箱密码',
  'page.baseinfo.ccemail.form.placeholder.port': '请填写服务器端口号',
  'page.baseinfo.ccemail.form.placeholder.smtp': '请填写邮箱服务器',
  'page.baseinfo.contracttemplate.column.title.action': '操作',
  'page.baseinfo.contracttemplate.column.title.business_id': '契约锁业务分类ID',
  'page.baseinfo.contracttemplate.column.title.create_time': '创建时间',
  'page.baseinfo.contracttemplate.column.title.description': '描述',
  'page.baseinfo.contracttemplate.column.title.id': 'ID',
  'page.baseinfo.contracttemplate.column.title.index': '序号',
  'page.baseinfo.contracttemplate.column.title.is_active': '是否可用',
  'page.baseinfo.contracttemplate.column.title.modal': '合同模板',
  'page.baseinfo.contracttemplate.column.title.modal1': '签章模板',
  'page.baseinfo.contracttemplate.column.title.name': '名称',
  'page.baseinfo.contracttemplate.column.title.template_id': '契约锁模板ID',
  'page.baseinfo.contracttemplate.column.title.update_time': '更新时间',
  'page.baseinfo.contracttemplate.form.label.business_id': '契约锁业务分类ID',
  'page.baseinfo.contracttemplate.form.label.description': '描述',
  'page.baseinfo.contracttemplate.form.label.is_active': '是否可用',
  'page.baseinfo.contracttemplate.form.label.name': '名称',
  'page.baseinfo.contracttemplate.form.label.search_text': '模糊搜索',
  'page.baseinfo.contracttemplate.form.label.template_id': '契约锁模板ID',
  'page.baseinfo.contracttemplate.form.placeholder.business_id': '请填写契约锁业务分类ID',
  'page.baseinfo.contracttemplate.form.placeholder.description': '请填写描述',
  'page.baseinfo.contracttemplate.form.placeholder.is_active': '请选择状态',
  'page.baseinfo.contracttemplate.form.placeholder.name': '请填写名称',
  'page.baseinfo.contracttemplate.form.placeholder.search_text': '请填写搜索内容',
  'page.baseinfo.contracttemplate.form.placeholder.template_id': '请填写契约锁模板ID',
  'page.baseinfo.country.column.title.action': '操作',
  'page.baseinfo.country.column.title.code': '国家代码',
  'page.baseinfo.country.column.title.create_time': '创建时间',
  'page.baseinfo.country.column.title.currency': '币种',
  'page.baseinfo.country.column.title.id': 'ID',
  'page.baseinfo.country.column.title.index': '序号',
  'page.baseinfo.country.column.title.is_active': '是否可用',
  'page.baseinfo.country.column.title.name': '国家名称',
  'page.baseinfo.country.column.title.nationality': '国籍',
  'page.baseinfo.country.column.title.symbol': '符号',
  'page.baseinfo.country.column.title.sort': '顺序号',
  'page.baseinfo.country.column.title.update_time': '更新时间',
  'page.baseinfo.country.form.label.code': '国家代码',
  'page.baseinfo.country.form.label.currency': '币种',
  'page.baseinfo.country.form.label.name': '国家名称',
  'page.baseinfo.country.form.label.nationality': '国籍',
  'page.baseinfo.country.form.label.symbol': '符号',
  'page.baseinfo.country.form.label.sort': '顺序号',
  'page.baseinfo.country.form.placeholder.code': '请填写国家代码',
  'page.baseinfo.country.form.placeholder.currency': '请填写币种',
  'page.baseinfo.country.form.placeholder.name': '请填写国家名称',
  'page.baseinfo.country.form.placeholder.nationality': '请填写国籍',
  'page.baseinfo.country.form.placeholder.symbol': '请填写符号',
  'page.baseinfo.country.form.placeholder.sort': '请填写顺序号',
  'page.baseinfo.emailaddress.column.title.action': '操作',
  'page.baseinfo.emailaddress.column.title.email': '邮箱地址',
  'page.baseinfo.emailaddress.column.title.id': 'ID',
  'page.baseinfo.emailaddress.column.title.index': '序号',
  'page.baseinfo.emailaddress.column.title.is_active': '是否可用',
  'page.baseinfo.emailaddress.column.title.name': '邮箱名称',
  'page.baseinfo.emailaddress.column.title.update_time': '更新时间',
  'page.baseinfo.emailaddress.form.label.email': '邮箱地址',
  'page.baseinfo.emailaddress.form.label.is_active': '是否可用',
  'page.baseinfo.emailaddress.form.label.name': '邮箱名称',
  'page.baseinfo.emailaddress.form.placeholder.email': '请填写邮箱地址',
  'page.baseinfo.emailaddress.form.placeholder.is_active': '请选择是否可用',
  'page.baseinfo.emailaddress.form.placeholder.name': '请填写邮箱名称',
  'page.baseinfo.emailtemplate.column.title.action': '操作',
  'page.baseinfo.emailtemplate.column.title.appendix_json_list': '附件',
  'page.baseinfo.emailtemplate.column.title.content': '内容',
  'page.baseinfo.emailtemplate.column.title.content_type': '内容类型',
  'page.baseinfo.emailtemplate.column.title.id': 'ID',
  'page.baseinfo.emailtemplate.column.title.image': '图片',
  'page.baseinfo.emailtemplate.column.title.index': '序号',
  'page.baseinfo.emailtemplate.column.title.is_active': '是否可用',
  'page.baseinfo.emailtemplate.column.title.name': '名称',
  'page.baseinfo.emailtemplate.column.title.title': '标题',
  'page.baseinfo.emailtemplate.column.title.update_time': '更新时间',
  'page.baseinfo.emailtemplate.form.label.content': '内容',
  'page.baseinfo.emailtemplate.form.label.content_type': '内容类型',
  'page.baseinfo.emailtemplate.form.label.is_active': '是否可用',
  'page.baseinfo.emailtemplate.form.label.name': '名称',
  'page.baseinfo.emailtemplate.form.label.search_text': '模糊搜索',
  'page.baseinfo.emailtemplate.form.label.title': '标题',
  'page.baseinfo.emailtemplate.form.placeholder.content': '请填写内容',
  'page.baseinfo.emailtemplate.form.placeholder.content_type': '请选择内容类型',
  'page.baseinfo.emailtemplate.form.placeholder.is_active': '请选择状态',
  'page.baseinfo.emailtemplate.form.placeholder.name': '请填写名称',
  'page.baseinfo.emailtemplate.form.placeholder.search_text': '请填写搜索内容',
  'page.baseinfo.emailtemplate.form.placeholder.title': '请填写标题',
  'page.baseinfo.endclient.column.title.action': '操作',
  'page.baseinfo.endclient.column.title.address': '地址',
  'page.baseinfo.endclient.column.title.contact_number': '联系方式',
  'page.baseinfo.endclient.column.title.id': 'ID',
  'page.baseinfo.endclient.column.title.index': '序号',
  'page.baseinfo.endclient.column.title.is_active': '是否可用',
  'page.baseinfo.endclient.column.title.name': '名称',
  'page.baseinfo.endclient.column.title.update_time': '更新时间',
  'page.baseinfo.endclient.form.label.address': '地址',
  'page.baseinfo.endclient.form.label.contact_number': '联系方式',
  'page.baseinfo.endclient.form.label.is_active': '是否可用',
  'page.baseinfo.endclient.form.label.name': '名称',
  'page.baseinfo.endclient.form.placeholder.address': '请填写地址',
  'page.baseinfo.endclient.form.placeholder.contact_number': '请填写联系方式',
  'page.baseinfo.endclient.form.placeholder.is_active': '请选择是否可用',
  'page.baseinfo.endclient.form.placeholder.name': '请填写名称',
  'page.baseinfo.exchangerate.column.title.action': '操作',
  'page.baseinfo.exchangerate.column.title.create_time': '创建时间',
  'page.baseinfo.exchangerate.column.title.local_currency__currency': '本地币种',
  'page.baseinfo.exchangerate.column.title.aim_currency__currency': '合同币种',
  'page.baseinfo.exchangerate.column.title.index': '序号',
  'page.baseinfo.exchangerate.column.title.is_active': '是否可用',
  'page.baseinfo.exchangerate.column.title.rate': '汇率',
  'page.baseinfo.exchangerate.column.title.service_month': '服务月份',
  'page.baseinfo.exchangerate.column.title.update_time': '更新时间',
  'page.baseinfo.exchangerate.form.label.currency_from': '原币种',
  'page.baseinfo.exchangerate.form.label.currency_to': '目标币种',
  'page.baseinfo.exchangerate.form.label.is_active': '是否可用',
  'page.baseinfo.exchangerate.form.label.rate': '汇率',
  'page.baseinfo.exchangerate.form.label.search_text': '模糊搜索',
  'page.baseinfo.exchangerate.form.placeholder.currency_from': '请填写原币种',
  'page.baseinfo.exchangerate.form.placeholder.currency_to': '请填写目标币种',
  'page.baseinfo.exchangerate.form.placeholder.is_active': '请选择状态',
  'page.baseinfo.exchangerate.form.placeholder.rate': '请填写汇率',
  'page.baseinfo.exchangerate.form.placeholder.search_text': '请填写搜索内容',
  'page.baseinfo.idtype.column.title.action': '操作',
  'page.baseinfo.idtype.column.title.create_time': '创建时间',
  'page.baseinfo.idtype.column.title.description': '描述',
  'page.baseinfo.idtype.column.title.id': 'ID',
  'page.baseinfo.idtype.column.title.index': '序号',
  'page.baseinfo.idtype.column.title.is_active': '是否可用',
  'page.baseinfo.idtype.column.title.name': '证件类别名',
  'page.baseinfo.idtype.column.title.update_time': '更新时间',
  'page.baseinfo.idtype.form.label.description': '描述',
  'page.baseinfo.idtype.form.label.name': '证件类别名',
  'page.baseinfo.idtype.form.placeholder.description': '请填写描述',
  'page.baseinfo.idtype.form.placeholder.name': '请填写证件类别名',
  'page.baseinfo.insurancecompany.column.title.action': '操作',
  'page.baseinfo.insurancecompany.column.title.address': '地址',
  'page.baseinfo.insurancecompany.column.title.agent': '保险经纪人',
  'page.baseinfo.insurancecompany.column.title.agent_contact': '经纪人联系方式',
  'page.baseinfo.insurancecompany.column.title.content': '理赔流程',
  'page.baseinfo.insurancecompany.column.title.company_name': '公司名称',
  'page.baseinfo.insurancecompany.column.title.contact': '联系人',
  'page.baseinfo.insurancecompany.column.title.create_time': '创建时间',
  'page.baseinfo.insurancecompany.column.title.email': '邮箱',
  'page.baseinfo.insurancecompany.column.title.id': 'ID',
  'page.baseinfo.insurancecompany.column.title.index': '序号',
  'page.baseinfo.insurancecompany.column.title.is_active': '是否可用',
  'page.baseinfo.insurancecompany.column.title.remark': '备注',
  'page.baseinfo.insurancecompany.column.title.update_time': '更新时间',
  'page.baseinfo.insurancecompany.form.label.address': '地址',
  'page.baseinfo.insurancecompany.form.label.agent': '保险经纪人',
  'page.baseinfo.insurancecompany.form.label.agent_contact': '经纪人联系方式',
  'page.baseinfo.insurancecompany.form.label.company_name': '公司名称',
  'page.baseinfo.insurancecompany.form.label.contact': '联系人',
  'page.baseinfo.insurancecompany.form.label.email': '邮箱',
  'page.baseinfo.insurancecompany.form.label.is_active': '是否可用',
  'page.baseinfo.insurancecompany.form.label.remark': '备注',
  'page.baseinfo.insurancecompany.form.placeholder.address': '请填写地址',
  'page.baseinfo.insurancecompany.form.placeholder.agent': '请填写保险经纪人',
  'page.baseinfo.insurancecompany.form.placeholder.agent_contact': '请填写经纪人联系方式',
  'page.baseinfo.insurancecompany.form.placeholder.company_name': '请填写公司名称',
  'page.baseinfo.insurancecompany.form.placeholder.contact': '请填写联系人',
  'page.baseinfo.insurancecompany.form.placeholder.email': '请填写邮箱',
  'page.baseinfo.insurancecompany.form.placeholder.remark': '请填写备注',
  'page.baseinfo.invoicetype.column.title.action': '操作',
  'page.baseinfo.invoicetype.column.title.create_time': '创建时间',
  'page.baseinfo.invoicetype.column.title.description': '描述',
  'page.baseinfo.invoicetype.column.title.id': 'ID',
  'page.baseinfo.invoicetype.column.title.index': '序号',
  'page.baseinfo.invoicetype.column.title.is_active': '是否可用',
  'page.baseinfo.invoicetype.column.title.name': '证件类别名',
  'page.baseinfo.invoicetype.column.title.update_time': '更新时间',
  'page.baseinfo.invoicetype.form.label.description': '描述',
  'page.baseinfo.invoicetype.form.label.name': '证件类别名',
  'page.baseinfo.invoicetype.form.placeholder.description': '请填写描述',
  'page.baseinfo.invoicetype.form.placeholder.name': '请填写证件类别名',
  'page.baseinfo.managementfeeformula.column.title.action': '操作',
  'page.baseinfo.managementfeeformula.column.title.create_time': '创建时间',
  'page.baseinfo.managementfeeformula.column.title.description': '描述',
  'page.baseinfo.managementfeeformula.column.title.formula': '公式',
  'page.baseinfo.managementfeeformula.column.title.id': 'ID',
  'page.baseinfo.managementfeeformula.column.title.index': '序号',
  'page.baseinfo.managementfeeformula.column.title.is_active': '是否可用',
  'page.baseinfo.managementfeeformula.column.title.name': '公式名称',
  'page.baseinfo.managementfeeformula.column.title.update_time': '更新时间',
  'page.baseinfo.managementfeeformula.form.label.description': '描述',
  'page.baseinfo.managementfeeformula.form.label.formula': '公式',
  'page.baseinfo.managementfeeformula.form.label.is_active': '状态',
  'page.baseinfo.managementfeeformula.form.label.name': '名称',
  'page.baseinfo.managementfeeformula.form.placeholder.description': '请填写描述',
  'page.baseinfo.managementfeeformula.form.placeholder.formula': '请填写公式',
  'page.baseinfo.managementfeeformula.form.placeholder.is_active': '请选择状态',
  'page.baseinfo.managementfeeformula.form.placeholder.name': '请填写公式名称',
  'page.baseinfo.notetype.column.title.action': '操作',
  'page.baseinfo.notetype.column.title.create_time': '创建时间',
  'page.baseinfo.notetype.column.title.description': '描述',
  'page.baseinfo.notetype.column.title.id': 'ID',
  'page.baseinfo.notetype.column.title.index': '序号',
  'page.baseinfo.notetype.column.title.is_active': '是否可用',
  'page.baseinfo.notetype.column.title.name': '记录类型',
  'page.baseinfo.notetype.column.title.update_time': '更新时间',
  'page.baseinfo.notetype.form.label.description': '描述',
  'page.baseinfo.notetype.form.label.flag': '标记',
  'page.baseinfo.notetype.form.label.name': '记录类型',
  'page.baseinfo.notetype.form.placeholder.description': '请填写描述',
  'page.baseinfo.notetype.form.placeholder.flag': '请填写标记',
  'page.baseinfo.notetype.form.placeholder.name': '请填写记录类型',
  'page.baseinfo.persontype.column.title.action': '操作',
  'page.baseinfo.persontype.column.title.create_time': '创建时间',
  'page.baseinfo.persontype.column.title.description': '描述',
  'page.baseinfo.persontype.column.title.id': 'ID',
  'page.baseinfo.persontype.column.title.index': '序号',
  'page.baseinfo.persontype.column.title.is_active': '是否可用',
  'page.baseinfo.persontype.column.title.name': '名称',
  'page.baseinfo.persontype.column.title.update_time': '更新时间',
  'page.baseinfo.persontype.form.label.description': '描述',
  'page.baseinfo.persontype.form.label.name': '名称',
  'page.baseinfo.persontype.form.placeholder.description': '请填写描述',
  'page.baseinfo.persontype.form.placeholder.name': '请填写名称',
  'page.baseinfo.recuitmentcompany.column.title.action': '操作',
  'page.baseinfo.recuitmentcompany.column.title.address': '顾问公司地址',
  'page.baseinfo.recuitmentcompany.column.title.client_id': 'ID',
  'page.baseinfo.recuitmentcompany.column.title.company_name': '顾问公司名',
  'page.baseinfo.recuitmentcompany.column.title.contact_number': '顾问公司联系方式',
  'page.baseinfo.recuitmentcompany.column.title.create_time': '创建时间',
  'page.baseinfo.recuitmentcompany.column.title.id': 'ID',
  'page.baseinfo.recuitmentcompany.column.title.index': '序号',
  'page.baseinfo.recuitmentcompany.column.title.is_active': '是否可用',
  'page.baseinfo.recuitmentcompany.column.title.update_time': '更新时间',
  'page.baseinfo.recuitmentcompany.column.title.vat_no': 'VATNo',
  'page.baseinfo.recuitmentcompany.column.title.bank_account_list': 'CC银行账户',
  'page.baseinfo.recuitmentcompany.form.label.address': '顾问公司地址',
  'page.baseinfo.recuitmentcompany.form.label.client_id': '顾问公司ID',
  'page.baseinfo.recuitmentcompany.form.label.company_name': '顾问公司名',
  'page.baseinfo.recuitmentcompany.form.label.contact_number': '顾问公司联系方式',
  'page.baseinfo.recuitmentcompany.form.label.vat_no': 'VATNo',
  'page.baseinfo.recuitmentcompany.form.placeholder.address': '请填写顾问公司地址',
  'page.baseinfo.recuitmentcompany.form.placeholder.client_id': '请填写顾问公司ID',
  'page.baseinfo.recuitmentcompany.form.placeholder.company_name': '请填写顾问公司名',
  'page.baseinfo.recuitmentcompany.form.placeholder.contact_number': '请填写顾问公司联系方式',
  'page.baseinfo.recuitmentcompany.form.placeholder.vat_no': '请填写VATNo',
  'page.baseinfo.recuitmentcompanyperson.column.title.action': '操作',
  'page.baseinfo.recuitmentcompanyperson.column.title.contact_number': '联系方式',
  'page.baseinfo.recuitmentcompanyperson.column.title.create_time': '创建时间',
  'page.baseinfo.recuitmentcompanyperson.column.title.id': 'ID',
  'page.baseinfo.recuitmentcompanyperson.column.title.index': '序号',
  'page.baseinfo.recuitmentcompanyperson.column.title.invoice_email_to': '请款单发送邮箱',
  'page.baseinfo.recuitmentcompanyperson.column.title.is_active': '是否可用',
  'page.baseinfo.recuitmentcompanyperson.column.title.name': '名称',
  'page.baseinfo.recuitmentcompanyperson.column.title.recuitment_company__company_name': '顾问公司',
  'page.baseinfo.recuitmentcompanyperson.column.title.update_time': '更新时间',
  'page.baseinfo.recuitmentcompanyperson.form.label.contact_number': '联系方式',
  'page.baseinfo.recuitmentcompanyperson.form.label.invoice_email_to': '请款单发送邮箱',
  'page.baseinfo.recuitmentcompanyperson.form.label.name': '名称',
  'page.baseinfo.recuitmentcompanyperson.form.label.recuitment_company_id': '顾问公司',
  'page.baseinfo.recuitmentcompanyperson.form.placeholder.contact_number': '请填写联系方式',
  'page.baseinfo.recuitmentcompanyperson.form.placeholder.invoice_email_to': '请填写请款单发送邮箱',
  'page.baseinfo.recuitmentcompanyperson.form.placeholder.name': '请填写名称',
  'page.baseinfo.recuitmentcompanyperson.form.placeholder.recuitment_company_id': '请选择顾问公司',
  'page.baseinfo.referencenumbertype.column.title.action': '操作',
  'page.baseinfo.referencenumbertype.column.title.create_time': '创建时间',
  'page.baseinfo.referencenumbertype.column.title.description': '类别描述',
  'page.baseinfo.referencenumbertype.column.title.id': 'ID',
  'page.baseinfo.referencenumbertype.column.title.index': '序号',
  'page.baseinfo.referencenumbertype.column.title.is_active': '是否可用',
  'page.baseinfo.referencenumbertype.column.title.is_adviser_select': '是否顾问端可选择',
  'page.baseinfo.referencenumbertype.column.title.name': '类别名称',
  'page.baseinfo.referencenumbertype.column.title.update_time': '更新时间',
  'page.baseinfo.referencenumbertype.form.label.description': '类别描述',
  'page.baseinfo.referencenumbertype.form.label.is_adviser_select': '是否顾问端可选择',
  'page.baseinfo.referencenumbertype.form.label.name': '类别名称',
  'page.baseinfo.referencenumbertype.form.placeholder.description': '请填写类别描述',
  'page.baseinfo.referencenumbertype.form.placeholder.name': '请填写类别名称',
  'page.baseinfo.status.column.title.action': '操作',
  'page.baseinfo.status.column.title.create_time': '创建时间',
  'page.baseinfo.status.column.title.description': '描述',
  'page.baseinfo.status.column.title.id': 'ID',
  'page.baseinfo.status.column.title.index': '序号',
  'page.baseinfo.status.column.title.is_active': '是否可用',
  'page.baseinfo.status.column.title.name': '记录类型',
  'page.baseinfo.status.column.title.update_time': '更新时间',
  'page.baseinfo.status.form.label.description': '描述',
  'page.baseinfo.status.form.label.name': '记录类型',
  'page.baseinfo.status.form.placeholder.description': '请填写描述',
  'page.baseinfo.status.form.placeholder.name': '请填写记录类型',
  'page.cerp.address.column.title.action': '操作',
  'page.cerp.address.column.title.address_type': '地址类型',
  'page.cerp.address.column.title.adviser__full_name': '隶属顾问',
  'page.cerp.address.column.title.country__name': '国家名称',
  'page.cerp.address.column.title.create_time': '创建时间',
  'page.cerp.address.column.title.display_name': '地址',
  'page.cerp.address.column.title.id': 'ID',
  'page.cerp.address.column.title.index': '序号',
  'page.cerp.address.column.title.is_active': '是否可用',
  'page.cerp.address.column.title.postcode': '邮编',
  'page.cerp.address.column.title.update_time': '更新时间',
  'page.cerp.address.form.label.address_type': '地址类型',
  'page.cerp.address.form.label.adviser__full_name': '顾问',
  'page.cerp.address.form.label.adviser_id': '隶属顾问',
  'page.cerp.address.form.label.city': '城市',
  'page.cerp.address.form.label.country_id': '国家',
  'page.cerp.address.form.label.display_name': '显示名称',
  'page.cerp.address.form.label.postcode': '邮编',
  'page.cerp.address.form.label.state': '州/省',
  'page.cerp.address.form.label.street': '街道',
  'page.cerp.address.form.placeholder.adviser__full_name': '请选择隶属顾问',
  'page.cerp.address.form.placeholder.adviser_id': '隶属顾问',
  'page.cerp.address.form.placeholder.city': '请填写城市',
  'page.cerp.address.form.placeholder.country_id': '请选择国家',
  'page.cerp.address.form.placeholder.display_name': '请填写显示名称',
  'page.cerp.address.form.placeholder.postcode': '请填写邮编',
  'page.cerp.address.form.placeholder.state': '请填写州/省',
  'page.cerp.address.form.placeholder.street': '请填写街道',
  'page.cerp.adviser.editModal.column.title.action': '操作',
  'page.cerp.adviser.editModal.column.title.birth_date': '出生日期',
  'page.cerp.adviser.editModal.column.title.create_time': '创建时间',
  'page.cerp.adviser.editModal.column.title.creater': '创建人',
  'page.cerp.adviser.editModal.column.title.employee_number': '工号',
  'page.cerp.adviser.editModal.column.title.full_name': '姓名',
  'page.cerp.adviser.editModal.column.title.home_address__display_name': '家庭地址',
  'page.cerp.adviser.editModal.column.title.id': 'ID',
  'page.cerp.adviser.editModal.column.title.index': '序号',
  'page.cerp.adviser.editModal.column.title.is_active': '是否可用',
  'page.cerp.adviser.editModal.column.title.mobile': '手机',
  'page.cerp.adviser.editModal.column.title.nationality__name': '国籍',
  'page.cerp.adviser.editModal.column.title.other_name': '其他姓名',
  'page.cerp.adviser.editModal.column.title.person_type__name': '个人类别',
  'page.cerp.adviser.editModal.column.title.personal_email': '个人邮箱',
  'page.cerp.adviser.editModal.column.title.phone': '电话',
  'page.cerp.adviser.editModal.column.title.qq': 'QQ账号',
  'page.cerp.adviser.editModal.column.title.skypelid': 'Skype ID',
  'page.cerp.adviser.editModal.column.title.status__name': '个人状态',
  'page.cerp.adviser.editModal.column.title.title': '称谓',
  'page.cerp.adviser.editModal.column.title.update_time': '更新时间',
  'page.cerp.adviser.editModal.column.title.wechat': '微信账号',
  'page.cerp.adviser.editModal.column.title.whats_app': 'WhatsApp账号',
  'page.cerp.adviser.editModal.column.title.work_address__display_name': '工作地址',
  'page.cerp.adviser.editModal.column.title.pay_currency__currency': '付款币种',
  'page.cerp.adviser.editModal.column.title.tag__name': '标签',
  'page.cerp.adviser.editModal.column.title.person_note': '备注',
  'page.cerp.adviser.editModal.column.title.work_email': '工作邮箱',
  'page.cerp.adviser.form.label.creater_id': '创建人',
  'page.cerp.adviser.form.label.employee_number': '顾问工号',
  'page.cerp.adviser.form.label.first_name': '名字',
  'page.cerp.adviser.form.label.home_address_id': '家庭地址',
  'page.cerp.adviser.form.label.is_active': '是否可用',
  'page.cerp.adviser.form.label.is_asc': '排序',
  'page.cerp.adviser.form.label.last_name': '姓',
  'page.cerp.adviser.form.label.mobile': '手机（格式请以00开头，如0086138xxxxxxxx）',
  'page.cerp.adviser.form.label.nationality_id': '国籍',
  'page.cerp.adviser.form.label.other_name': '其他名字',
  'page.cerp.adviser.form.label.person_type_id': '个人类别',
  'page.cerp.adviser.form.label.personal_email': '个人邮箱',
  'page.cerp.adviser.form.label.phone': '电话',
  'page.cerp.adviser.form.label.qq': 'QQ账号',
  'page.cerp.adviser.form.label.search_text': '模糊搜索',
  'page.cerp.adviser.form.label.skypelid': 'Skype ID',
  'page.cerp.adviser.form.label.status': '个人状态',
  'page.cerp.adviser.form.label.title': '称谓',
  'page.cerp.adviser.form.label.wechat': '微信账号',
  'page.cerp.adviser.form.label.whats_app': 'WhatsApp账号',
  'page.cerp.adviser.form.label.work_address_id': '工作地址',
  'page.cerp.adviser.form.label.tag_id': '标签',
  'page.cerp.adviser.form.label.work_email': '工作邮箱',
  'page.cerp.adviser.form.placeholder.creater_id': '请选择创建人',
  'page.cerp.adviser.form.placeholder.employee_number': '请填写顾问工号',
  'page.cerp.adviser.form.placeholder.first_name': '请填写名字',
  'page.cerp.adviser.form.placeholder.home_address_id': '请选择家庭地址',
  'page.cerp.adviser.form.placeholder.last_name': '请填写姓',
  'page.cerp.adviser.form.placeholder.mobile': '请填写手机',
  'page.cerp.adviser.form.placeholder.nationality_id': '请选择国籍',
  'page.cerp.adviser.form.placeholder.other_name': '请填写其他名字',
  'page.cerp.adviser.form.placeholder.person_type_id': '请选择个人类别',
  'page.cerp.adviser.form.placeholder.personal_email': '请填写个人邮箱',
  'page.cerp.adviser.form.placeholder.phone': '请填写电话',
  'page.cerp.adviser.form.placeholder.qq': '请填写QQ账号',
  'page.cerp.adviser.form.placeholder.search_text': '请填写搜索内容',
  'page.cerp.adviser.form.placeholder.skypelid': '请填写Skype ID',
  'page.cerp.adviser.form.placeholder.status': '请选择个人状态',
  'page.cerp.adviser.form.placeholder.title': '请选择称谓',
  'page.cerp.adviser.form.placeholder.wechat': '请填写微信账号',
  'page.cerp.adviser.form.placeholder.whats_app': '请填写WhatsApp账号',
  'page.cerp.adviser.form.placeholder.work_address_id': '请选择工作地址',
  'page.cerp.adviser.form.placeholder.tag_id': '请选择标签',
  'page.cerp.adviser.form.placeholder.work_email': '请填写工作邮箱',
  'page.cerp.bankaccount.column.title.account_holder': '账户持有人',
  'page.cerp.bankaccount.column.title.account_number': '账号',
  'page.cerp.bankaccount.column.title.action': '操作',
  'page.cerp.bankaccount.column.title.adviser__full_name': '隶属顾问',
  'page.cerp.bankaccount.column.title.bank_address': '开户行地址',
  'page.cerp.bankaccount.column.title.bank_name': '银行名称',
  'page.cerp.bankaccount.column.title.bic_or_swift_code': 'bic_or_swift_code',
  'page.cerp.bankaccount.column.title.cnaps': 'cnaps',
  'page.cerp.bankaccount.column.title.create_time': '创建时间',
  'page.cerp.bankaccount.column.title.english_name': '银行英文名称',
  'page.cerp.bankaccount.column.title.iban': 'iban',
  'page.cerp.bankaccount.column.title.id': 'ID',
  'page.cerp.bankaccount.column.title.index': '序号',
  'page.cerp.bankaccount.column.title.insurance_company__company_name': '保险公司',
  'page.cerp.bankaccount.column.title.is_active': '是否可用',
  'page.cerp.bankaccount.column.title.is_current': '是否当前账号',
  'page.cerp.bankaccount.column.title.note': '备注',
  'page.cerp.bankaccount.column.title.sort_code_or_branch_code': 'sort_code_or_branch_code',
  'page.cerp.bankaccount.column.title.update_time': '更新时间',
  'page.cerp.bankaccount.form.label.account_holder': '账户持有人',
  'page.cerp.bankaccount.form.label.account_number': '账号',
  'page.cerp.bankaccount.form.label.adviser__full_name': '顾问',
  'page.cerp.bankaccount.form.label.adviser_id': '隶属顾问',
  'page.cerp.bankaccount.form.label.bank_address': '开户行地址',
  'page.cerp.bankaccount.form.label.bank_name': '银行名称',
  'page.cerp.bankaccount.form.label.bic_or_swift_code': 'bic_or_swift_code',
  'page.cerp.bankaccount.form.label.cnaps': 'cnaps',
  'page.cerp.bankaccount.form.label.english_name': '银行英文名称',
  'page.cerp.bankaccount.form.label.iban': 'iban',
  'page.cerp.bankaccount.form.label.insurance_company_id': '保险公司',
  'page.cerp.bankaccount.form.label.is_active': '是否可用',
  'page.cerp.bankaccount.form.label.is_current': '是否当前账号',
  'page.cerp.bankaccount.form.label.kind': '账号种类',
  'page.cerp.bankaccount.form.label.sort_code_or_branch_code': 'sort_code_or_branch_code',
  'page.cerp.bankaccount.form.placeholder.account_holder': '请填写账户持有人',
  'page.cerp.bankaccount.form.placeholder.account_number': '请填写账号',
  'page.cerp.bankaccount.form.placeholder.adviser__full_name': '请选择隶属顾问',
  'page.cerp.bankaccount.form.placeholder.adviser_id': '隶属顾问',
  'page.cerp.bankaccount.form.placeholder.bank_address': '请填写开户行地址',
  'page.cerp.bankaccount.form.placeholder.bank_name': '请填写银行名称',
  'page.cerp.bankaccount.form.placeholder.bic_or_swift_code': '请填写bic_or_swift_code',
  'page.cerp.bankaccount.form.placeholder.cnaps': '请填写cnaps',
  'page.cerp.bankaccount.form.placeholder.english_name': '请填写银行英文名称',
  'page.cerp.bankaccount.form.placeholder.iban': '请填写iban',
  'page.cerp.bankaccount.form.placeholder.insurance_company_id': '请选择保险公司',
  'page.cerp.bankaccount.form.placeholder.is_current': '请填写是否当前账号',
  'page.cerp.bankaccount.form.placeholder.sort_code_or_branch_code':
    '请填写sort_code_or_branch_code',
  'page.cerp.bankaccount1.column.title.account_holder': '账户持有人',
  'page.cerp.bankaccount1.column.title.account_number': '账号',
  'page.cerp.bankaccount1.column.title.action': '操作',
  'page.cerp.bankaccount1.column.title.bank_address': '开户行地址',
  'page.cerp.bankaccount1.column.title.bank_name': '银行名称',
  'page.cerp.bankaccount1.column.title.bic_or_swift_code': 'bic_or_swift_code',
  'page.cerp.bankaccount1.column.title.cnaps': 'cnaps',
  'page.cerp.bankaccount1.column.title.create_time': '创建时间',
  'page.cerp.bankaccount1.column.title.english_name': '银行英文名称',
  'page.cerp.bankaccount1.column.title.iban': 'iban',
  'page.cerp.bankaccount1.column.title.id': 'ID',
  'page.cerp.bankaccount1.column.title.index': '序号',
  'page.cerp.bankaccount1.column.title.insurance_company__company_name': '保险公司',
  'page.cerp.bankaccount1.column.title.is_active': '是否可用',
  'page.cerp.bankaccount1.column.title.is_current': '是否当前账号',
  'page.cerp.bankaccount1.column.title.note': '备注',
  'page.cerp.bankaccount1.column.title.sort_code_or_branch_code': 'sort_code_or_branch_code',
  'page.cerp.bankaccount1.column.title.update_time': '更新时间',
  'page.cerp.bankaccount1.form.label.account_holder': '账户持有人',
  'page.cerp.bankaccount1.form.label.account_number': '账号',
  'page.cerp.bankaccount1.form.label.adviser_id': '隶属顾问',
  'page.cerp.bankaccount1.form.label.bank_address': '开户行地址',
  'page.cerp.bankaccount1.form.label.bank_name': '银行名称',
  'page.cerp.bankaccount1.form.label.bic_or_swift_code': 'bic_or_swift_code',
  'page.cerp.bankaccount1.form.label.cnaps': 'cnaps',
  'page.cerp.bankaccount1.form.label.english_name': '银行英文名称',
  'page.cerp.bankaccount1.form.label.iban': 'iban',
  'page.cerp.bankaccount1.form.label.insurance_company_id': '保险公司',
  'page.cerp.bankaccount1.form.label.is_active': '是否可用',
  'page.cerp.bankaccount1.form.label.is_current': '是否当前账号',
  'page.cerp.bankaccount1.form.label.kind': '账号种类',
  'page.cerp.bankaccount1.form.label.sort_code_or_branch_code': 'sort_code_or_branch_code',
  'page.cerp.bankaccount1.form.placeholder.account_holder': '请填写账户持有人',
  'page.cerp.bankaccount1.form.placeholder.account_number': '请填写账号',
  'page.cerp.bankaccount1.form.placeholder.adviser_id': '隶属顾问',
  'page.cerp.bankaccount1.form.placeholder.bank_address': '请填写开户行地址',
  'page.cerp.bankaccount1.form.placeholder.bank_name': '请填写银行名称',
  'page.cerp.bankaccount1.form.placeholder.bic_or_swift_code': '请填写bic_or_swift_code',
  'page.cerp.bankaccount1.form.placeholder.cnaps': '请填写cnaps',
  'page.cerp.bankaccount1.form.placeholder.english_name': '请填写银行英文名称',
  'page.cerp.bankaccount1.form.placeholder.iban': '请填写iban',
  'page.cerp.bankaccount1.form.placeholder.insurance_company_id': '请选择保险公司',
  'page.cerp.bankaccount1.form.placeholder.is_current': '请填写是否当前账号',
  'page.cerp.bankaccount1.form.placeholder.sort_code_or_branch_code':
    '请填写sort_code_or_branch_code',
  'page.cerp.banktransaction.column.title.action': '操作',
  'page.cerp.banktransaction.form.label.currency_id': '币种',
  'page.cerp.banktransaction.column.title.amount': '金额',
  'page.cerp.banktransaction.column.title.cc_bank_account__account_flag': 'CC银行账户',
  'page.cerp.banktransaction.column.title.create_time': '创建时间',
  'page.cerp.banktransaction.column.title.date': '交易日期',
  'page.cerp.banktransaction.column.title.description': '备注',
  'page.cerp.banktransaction.column.title.id': 'ID',
  'page.cerp.banktransaction.column.title.index': '序号',
  'page.cerp.banktransaction.column.title.is_active': '是否可用',
  'page.cerp.banktransaction.column.title.name': '名称',
  'page.cerp.banktransaction.column.title.transaction_type': '流水种类',
  'page.cerp.banktransaction.column.title.update_time': '更新时间',
  'page.cerp.banktransaction.form.label.amount': '金额',
  'page.cerp.banktransaction.form.label.cc_bank_account_id': 'CC银行账户',
  'page.cerp.banktransaction.form.label.date': '日期',
  'page.cerp.banktransaction.form.label.description': '备注',
  'page.cerp.banktransaction.form.label.name': '名称',
  'page.cerp.banktransaction.form.label.transaction_type': '流水种类',
  'page.cerp.banktransaction.form.placeholder.amount': '请填写金额',
  'page.cerp.banktransaction.form.placeholder.cc_bank_account_id': '请选择CC银行账户',
  'page.cerp.banktransaction.form.placeholder.date': '请填写日期',
  'page.cerp.banktransaction.form.placeholder.description': '请填写备注',
  'page.cerp.banktransaction.form.placeholder.name': '请填写名称',
  'page.cerp.banktransaction.form.placeholder.transaction_type': '请选择流水种类',
  'page.cerp.beneficiary.column.title.action': '操作',
  'page.cerp.beneficiary.column.title.create_time': '创建时间',
  'page.cerp.beneficiary.column.title.date_of_birth': '出生日期',
  'page.cerp.beneficiary.column.title.gender': '性别',
  'page.cerp.beneficiary.column.title.id': 'ID',
  'page.cerp.beneficiary.column.title.id_expiry_date': '证件有效期',
  'page.cerp.beneficiary.column.title.relation': '与被保险人关系',
  'page.cerp.beneficiary.column.title.ratio': '受益比例',
  'page.cerp.beneficiary.column.title.id_number': '证件号',
  'page.cerp.beneficiary.column.title.id_type__name': '证件类别名',
  'page.cerp.beneficiary.column.title.index': '序号',
  'page.cerp.beneficiary.column.title.insurance__policy_no': '保险单号',
  'page.cerp.beneficiary.column.title.is_active': '是否可用',
  'page.cerp.beneficiary.column.title.name': '姓名',
  'page.cerp.beneficiary.column.title.adviser__full_name': '顾问名',
  'page.cerp.beneficiary.column.title.adviser__employee_number': '顾问工号',
  'page.cerp.beneficiary.column.title.update_time': '更新时间',
  'page.cerp.beneficiary.form.label.adviser_id': '隶属顾问',
  'page.cerp.beneficiary.form.label.date_of_birth': '出生日期',
  'page.cerp.beneficiary.form.label.gender': '性别',
  'page.cerp.beneficiary.form.label.id_expiry_date': '证件有效期',
  'page.cerp.beneficiary.form.label.relation': '与被保险人关系',
  'page.cerp.beneficiary.form.label.ratio': '受益比例',
  'page.cerp.beneficiary.form.label.id_number': '证件号',
  'page.cerp.beneficiary.form.label.id_type_id': '证件类别',
  'page.cerp.beneficiary.form.label.name': '姓名',
  'page.cerp.beneficiary.form.placeholder.adviser_id': '隶属顾问',
  'page.cerp.beneficiary.form.placeholder.date_of_birth': '请填写出生日期',
  'page.cerp.beneficiary.form.placeholder.id_expiry_date': '请填写证件有效期',
  'page.cerp.beneficiary.form.placeholder.relation': '请填写与被保险人关系',
  'page.cerp.beneficiary.form.placeholder.ratio': '请填写受益比例',
  'page.cerp.beneficiary.form.placeholder.id_number': '请填写证件号',
  'page.cerp.beneficiary.form.placeholder.id_type_id': '请选择证件类别',
  'page.cerp.beneficiary.form.placeholder.name': '请填写姓名',
  'page.cerp.changehistory.column.title.action': '操作',
  'page.cerp.changehistory.column.title.create_time': '创建时间',
  'page.cerp.changehistory.column.title.id': 'ID',
  'page.cerp.changehistory.column.title.object_id': '数据id',
  'page.cerp.changehistory.column.title.index': '序号',
  'page.cerp.changehistory.column.title.is_active': '是否可用',
  'page.cerp.changehistory.column.title.model_name': 'model名称',
  'page.cerp.changehistory.column.title.model_str': 'model标记',
  'page.cerp.changehistory.column.title.new_json': '新数据json',
  'page.cerp.changehistory.column.title.user_phone': '手机号',
  'page.cerp.changehistory.column.title.user_name': '操作人',
  'page.cerp.changehistory.column.title.old_json': '旧数据json',
  'page.cerp.changehistory.column.title.update_time': '更新时间',
  'page.cerp.changehistory.form.label.model_name': 'model名称',
  'page.cerp.changehistory.form.label.model_str': 'model标记',
  'page.cerp.changehistory.form.placeholder.model_name': '请填写model名称',
  'page.cerp.changehistory.form.placeholder.model_str': '请填写model标记',
  'page.cerp.contract.colums.column.title.accommodation_rate': '住宿费率',
  'page.cerp.contract.colums.column.title.action': '操作',
  'page.cerp.contract.colums.column.title.adviser__full_name': '隶属顾问',
  'page.cerp.contract.colums.column.title.adviser__employee_number': '工号',
  'page.cerp.contract.colums.column.title.appendix_json_list': '附件',
  'page.cerp.contract.colums.column.title.insurance__effective_date_time': '保险起保日期', // '保险起保日期',
  'page.cerp.contract.colums.column.title.insurance__expiry_date_time': '保险停保日期', // '保险停保日期',
  'page.cerp.contract.colums.column.title.bank_charge': '银行转账费',
  'page.cerp.contract.colums.column.title.consultant_bank_charge': '顾问转账费',
  'page.cerp.contract.colums.column.title.company_bank_charge': '顾问公司转账费',
  'page.cerp.contract.colums.column.title.basic_rate': '基本单位薪资',
  'page.cerp.contract.colums.column.title.cc_email__name': 'CC邮箱',
  'page.cerp.contract.colums.column.title.cc_management_fee': 'cc管理费',
  'page.cerp.contract.colums.column.title.cc_company__name': 'CC公司',
  'page.cerp.contract.colums.column.title.cc_management_fee_rate': 'cc管理费率（%）',
  'page.cerp.contract.colums.column.title.create_time': '创建时间',
  'page.cerp.contract.colums.column.title.founder': '创建人', // '创建人',
  'page.cerp.contract.colums.column.title.reviser': '修改人', // '修改人',
  'page.cerp.contract.colums.column.title.income_tax': '个税代缴', // '个税代缴',
  'page.cerp.contract.colums.column.title.social_insurance': '社保代缴', // '个税代缴',
  'page.cerp.contract.colums.column.title.fte': '全时约当数', // '全时约当数',
  'page.cerp.contract.colums.column.title.annual_leave': '年假天数', // '年假天数',
  'page.cerp.contract.colums.column.title.work_hour_template_id': '工时确认邮箱模板', // '工时确认邮箱模板',
  'page.cerp.contract.colums.column.title.solution_type': '是否运用本地解决方案', // '是否运用本地解决方案',
  'page.cerp.contract.colums.column.title.creater': '当前合同负责人',
  'page.cerp.contract.colums.column.title.currency__currency': '薪资币种',
  'page.cerp.contract.colums.column.title.pay_currency__currency': '付款币种',
  'page.cerp.contract.colums.column.title.email_record__log': '邮件发送日志',
  'page.cerp.contract.colums.column.title.email_record__status': '邮件发送状态',
  'page.cerp.contract.colums.column.title.email_record__create_time': '邮件发送时间',
  'page.cerp.contract.colums.column.title.expiration_email_record__log': '合同到期提醒邮件发送日志',
  'page.cerp.contract.colums.column.title.expiration_email_record__status':
    '合同到期提醒邮件发送状态',
  'page.cerp.contract.colums.column.title.expiration_email_record__create_time':
    '合同到期提醒邮件发送时间',
  'page.cerp.contract.colums.column.title.sign_email_record__log': '签署合同确认邮件发送日志',
  'page.cerp.contract.colums.column.title.sign_email_record__status': '签署合同确认邮件发送状态',
  'page.cerp.contract.colums.column.title.sign_email_record__create_time':
    '签署合同确认邮件发送时间',
  'page.cerp.contract.colums.column.title.confirm_date': '顾问签署时间',
  'page.cerp.contract.colums.column.title.cc_sign_date': 'CC签署时间',
  'page.cerp.contract.colums.column.title.end_client__name': '终端客户',
  'page.cerp.contract.colums.column.title.end_date': '合同结束日期',
  'page.cerp.contract.colums.column.title.id': 'ID',
  'page.cerp.contract.colums.column.title.index': '序号',
  'page.cerp.contract.colums.column.title.insurance_type': '保险类别',
  'page.cerp.contract.colums.column.title.invoice_email_template__name': '请款单邮箱模板',
  'page.cerp.contract.colums.column.title.is_active': '是否可用',
  'page.cerp.contract.colums.column.title.is_bank_charge_payment': '银行转账费支付方式',
  'page.cerp.contract.colums.column.title.is_current': '是否当前合同',
  'page.cerp.contract.colums.column.title.insurance_payment_flag': '保险费支付方式',
  'page.cerp.contract.colums.column.title.local_deduct_fee': '本地扣减费',
  'page.cerp.contract.colums.column.title.local_salary': '本地发薪数值',
  'page.cerp.contract.colums.column.title.local_management_fee': '本地管理费',
  'page.cerp.contract.colums.column.title.local_management_fee_fixed': '本地发薪工资',
  'page.cerp.contract.colums.column.title.local_pay_currency__currency': '本地发薪币种',
  'page.cerp.contract.colums.column.title.local_management_fee_rate': '本地管理费率（%）',
  'page.cerp.contract.colums.column.title.management_fee_formula__name': '管理费计算方式',
  'page.cerp.contract.colums.column.title.management_fee_payment_flag': '管理费支付方式',
  'page.cerp.contract.colums.column.title.qiyuesuo_status': '合同状态',
  'page.cerp.contract.colums.column.title.overtime_rate': '加班费率',
  'page.cerp.contract.colums.column.title.is_three_upload': '三方合同上传',
  'page.cerp.contract.colums.column.title.is_double_upload': '双方合同上传',
  'page.cerp.contract.colums.column.title.payslip_email_template__name': '工资单邮箱模板',
  'page.cerp.contract.colums.column.title.tip_day': '合同到期提醒天数',
  'page.cerp.contract.colums.column.title.expiration_email_template__name': '合同到期提醒邮箱模板',
  'page.cerp.contract.colums.column.title.sign_email_template__name': '签署合同确认邮箱模板',
  'page.cerp.contract.colums.column.title.wechat_appendix_json_list': '小程序附件',
  'page.cerp.contract.colums.column.title.qiyuesuo_contract_id': '契约锁合同ID',
  'page.cerp.contract.colums.column.title.rate_type': '薪资种类',
  'page.cerp.contract.colums.column.title.recuitment_company__company_name': '顾问公司',
  'page.cerp.contract.colums.column.title.recuitment_company__vat_no': 'VATNo',
  'page.cerp.contract.colums.column.title.remark': '备注',
  'page.cerp.contract.colums.column.title.tip__name': '重要提示',
  'page.cerp.contract.colums.column.title.start_date': '合同起始日期',
  'page.cerp.contract.colums.column.title.termination_date': '终止日期',
  'page.cerp.contract.colums.column.title.update_time': '更新时间',
  'page.cerp.contract.colums.column.title.work_location__name': '工作地点',
  'page.cerp.banktransaction.column.title.currency__currency': '币种',
  'page.cerp.banktransaction.column.title.adviser__full_name': '顾问',
  'page.cerp.banktransaction.column.title.adviser__employee_number': '顾问工号',
  'page.cerp.banktransaction.column.title.transaction__currency__currency': '币种',
  'page.cerp.banktransaction.column.title.remittance_set__transaction__currency__currency': '币种',
  'page.cerp.payslip.column.title.invoice__contract__currency__currency': '合同薪资币种',
  'page.cerp.payslip.column.title.adviser__person_note': '顾问备注',
  'page.cerp.remittance.column.title.remittance_set__transaction__currency__currency': '币种',
  'page.cerp.remittance.column.title.adviser__employee_number': '顾问工号',
  'page.cerp.remittance.column.title.to_do_num': '待办未完成',
  'page.cerp.remittanceset.column.title.transaction__currency__currency': '银行流水币种',
  'page.cerp.remittanceset.column.title.currency__currency': '币种',
  'page.cerp.remittanceset.column.title.transaction__amount': '银行流水金额',
  'page.cerp.remittanceset.column.title.recuitment_company__company_name': '顾问公司',
  'page.cerp.remittanceset.column.title.bank_charge__name': '银行手续费流水',
  'page.cerp.remittanceset.column.title.bank_charge__amount': '银行手续费流水金额',
  'page.cerp.contract.form.label.address': '地址',
  'page.cerp.contract.form.label.adviser_id': '隶属顾问',
  'page.cerp.contract.form.label.cc_company_id': 'CC公司',
  'page.cerp.contract.form.label.cc_email_id': 'CC邮箱',
  'page.cerp.contract.form.label.contact_number': '联系方式',
  'page.cerp.contract.form.label.contact_person_id': '顾问公司联系人',
  'page.cerp.contract.form.label.contract_template_id': '合同模板',
  'page.cerp.contract.form.label.currency_id': '薪资币种',
  'page.cerp.contract.form.label.end_client_id': '终端用户',
  'page.cerp.contract.form.label.insurance_type': '保险类型',
  'page.cerp.contract.form.label.invoice_email_template_id': '请款单邮箱模板',
  'page.cerp.contract.form.label.is_active': '是否可用',
  'page.cerp.contract.form.label.is_bank_charge_payment': '银行转账费支付方式',
  'page.cerp.contract.form.label.is_current': '是否当前合同',
  'page.cerp.contract.form.label.solution_type': '是否有本地解决方案',
  'page.cerp.contract.form.label.insurance_payment_flag': '保险费支付方式',
  'page.cerp.contract.form.label.management_fee_formula_id': '管理费计算方式',
  'page.cerp.contract.form.label.management_fee_payment_flag': '管理费支付方式',
  'page.cerp.contract.form.label.name': '名称',
  'page.cerp.contract.form.label.payslip_email_template_id': '工资单邮箱模板',
  'page.cerp.contract.form.label.rate_type': '薪资种类',
  'page.cerp.contract.form.label.recuitment_company_id': '顾问公司',
  'page.cerp.contract.form.label.recuitment_company_person_id': '请款邮箱地址',
  'page.cerp.contract.form.label.remark': '备注',
  'page.cerp.contract.form.label.search_text': '模糊搜索',
  'page.cerp.contract.form.label.work_location_id': '工作地点',
  'page.cerp.contract.form.placeholder.address': '请填写地址',
  'page.cerp.banktransaction.form.placeholder.currency_id': '请选择币种',
  'page.cerp.contract.form.placeholder.adviser_id': '隶属顾问',
  'page.cerp.contract.form.placeholder.cc_company_id': '请选择CC公司',
  'page.cerp.contract.form.placeholder.cc_email_id': '请选择CC邮箱',
  'page.cerp.contract.form.placeholder.contact_number': '请填写联系方式',
  'page.cerp.contract.form.placeholder.contact_person_id': '请选择顾问公司联系人',
  'page.cerp.contract.form.placeholder.contract_template_id': '请选择合同模板',
  'page.cerp.contract.form.placeholder.currency_id': '请选择薪资币种',
  'page.cerp.contract.form.placeholder.end_client_id': '请选择终端用户',
  'page.cerp.contract.form.placeholder.insurance_type': '请选择保险类型',
  'page.cerp.contract.form.placeholder.invoice_email_template_id': '请选择请款单邮箱模板',
  'page.cerp.contract.form.placeholder.management_fee_formula_id': '请选择管理费计算方式',
  'page.cerp.contract.form.placeholder.name': '请填写名称',
  'page.cerp.contract.form.placeholder.payslip_email_template_id': '请选择工资单邮箱模板',
  'page.cerp.contract.form.placeholder.rate_type': '请选择薪资种类',
  'page.cerp.contract.form.placeholder.recuitment_company_id': '请选择顾问公司',
  'page.cerp.contract.form.placeholder.recuitment_company_person_id': '请选择请款邮箱地址',
  'page.cerp.contract.form.placeholder.remark': '请填写备注',
  'page.cerp.contract.form.placeholder.search_text': '请填写搜索内容',
  'page.cerp.contract.form.placeholder.work_location_id': '请选择工作地点',
  'page.cerp.customfield.column.title.action': '操作',
  'page.cerp.customfield.column.title.create_time': '创建时间',
  'page.cerp.customfield.column.title.custom_field_name': '自定义项描述',
  'page.cerp.customfield.column.title.custom_field_value': '自定义项值',
  'page.cerp.customfield.column.title.id': 'ID',
  'page.cerp.customfield.column.title.index': '序号',
  'page.cerp.customfield.column.title.is_active': '是否可用',
  'page.cerp.customfield.column.title.update_time': '更新时间',
  'page.cerp.customfield.form.label.custom_field_name': '自定义项名字',
  'page.cerp.customfield.form.label.custom_field_set_id': '自定义项集',
  'page.cerp.customfield.form.label.custom_field_value': '自定义项值',
  'page.cerp.customfield.form.label.is_active': '是否可用',
  'page.cerp.customfield.form.label.search_text': '模糊搜索',
  'page.cerp.customfield.form.placeholder.custom_field_name': '请填写自定义项名字',
  'page.cerp.customfield.form.placeholder.custom_field_set_id': '请选择自定义项集',
  'page.cerp.customfield.form.placeholder.custom_field_value': '请填写自定义项值',
  'page.cerp.customfield.form.placeholder.is_active': '请选择是否可用',
  'page.cerp.customfield.form.placeholder.search_text': '请填写搜索内容',
  'page.cerp.customfieldset.column.title.action': '操作',
  'page.cerp.customfieldset.column.title.create_time': '创建时间',
  'page.cerp.customfieldset.column.title.description': '描述',
  'page.cerp.customfieldset.column.title.id': 'ID',
  'page.cerp.customfieldset.column.title.index': '序号',
  'page.cerp.customfieldset.column.title.is_active': '是否可用',
  'page.cerp.customfieldset.column.title.name': '证件类别名',
  'page.cerp.customfieldset.column.title.update_time': '更新时间',
  'page.cerp.customfieldset.form.label.description': '描述',
  'page.cerp.customfieldset.form.label.name': '证件类别名',
  'page.cerp.customfieldset.form.placeholder.description': '请填写描述',
  'page.cerp.customfieldset.form.placeholder.name': '请填写证件类别名',
  'page.cerp.insurance.column.title.action': '操作',
  'page.cerp.insurance.column.title.adviser.full_name': '隶属顾问',
  'page.cerp.insurance.column.title.appendix_json': '保单',
  'page.cerp.insurance.column.title.other_appendix_json': '其他附件',
  'page.cerp.insurance.column.title.beneficiaries': '受益人',
  'page.cerp.insurance.column.title.create_time': '创建时间',
  'page.cerp.insurance.column.title.effective_date_time': '起保日期时间',
  'page.cerp.insurance.column.title.expiry_date_time': '停保日期时间',
  'page.cerp.insurance.column.title.id': 'ID',
  'page.cerp.insurance.column.title.index': '序号',
  'page.cerp.insurance.column.title.insurance_company.company_name': '保险公司',
  'page.cerp.insurance.column.title.insurance_fee': '保费',
  'page.cerp.insurance.column.title.insurance_type': '保险类型',
  'page.cerp.insurance.column.title.is_active': '是否可用',
  'page.cerp.insurance.column.title.is_current': '当前保险',
  'page.cerp.insurance.column.title.month_insurance_fee': '每月保费',
  'page.cerp.insurance.column.title.remark': '备注',
  'page.cerp.insurance.column.title.policy_no': '保险单号',
  'page.cerp.insurance.column.title.update_time': '更新时间',
  'page.cerp.insurance.form.label.adviser__full_name': '顾问',
  'page.cerp.insurance.form.label.adviser_id': '隶属顾问',
  'page.cerp.insurance.form.label.effective_date_time': '起保日期时间',
  'page.cerp.insurance.form.label.expiry_date_time': '停保日期时间',
  'page.cerp.insurance.form.label.insurance_company_id': '保险公司',
  'page.cerp.insurance.form.label.insurance_fee': '保费',
  'page.cerp.insurance.form.label.is_current': '当前保险',
  'page.cerp.insurance.form.label.month_insurance_fee': '每月保费',
  'page.cerp.insurance.form.label.policy_no': '保险单号',
  'page.cerp.insurance.form.placeholder.adviser__full_name': '请选择隶属顾问',
  'page.cerp.insurance.form.placeholder.adviser_id': '隶属顾问',
  'page.cerp.insurance.form.placeholder.effective_date_time': '请填写起保日期时间',
  'page.cerp.insurance.form.placeholder.expiry_date_time': '请填写停保日期时间',
  'page.cerp.insurance.form.placeholder.insurance_company_id': '请选择保险公司',
  'page.cerp.insurance.form.placeholder.insurance_fee': '请填写保费',
  'page.cerp.insurance.form.placeholder.is_current': '请填写当前保险',
  'page.cerp.insurance.form.placeholder.month_insurance_fee': '请填写每月保费',
  'page.cerp.insurance.form.placeholder.policy_no': '请填写保险单号',
  'page.cerp.invoice.column.title.accommodation_amount': '住宿总额',
  'page.cerp.invoice.column.title.accommodation_unit': '宿款单位',
  'page.cerp.invoice.column.title.action': '操作',
  'page.cerp.invoice.column.title.adviser__full_name': '顾问',
  'page.cerp.invoice.column.title.adviser__employee_number': '工号',
  'page.cerp.invoice.column.title.adviser__end_date': '顾问结束日期',
  'page.cerp.invoice.column.title.adviser__status': '顾问个人状态',
  'page.cerp.invoice.column.title.bank_account__account_name': 'CC收款账户',
  'page.cerp.invoice.column.title.founder': '创建人', // '创建人',
  'page.cerp.invoice.column.title.reviser': '修改人', // '修改人',
  'page.cerp.invoice.column.title.appendix_json': '工时证明文件', // '工时证明文件',
  'page.cerp.invoice.column.title.payment_status': '付款状态', // '工时证明文件',
  'page.cerp.invoice.column.title.bank_account__account_number': '账号',
  'page.cerp.invoice.column.title.bank_charge_cost': '银行转账费',
  'page.cerp.invoice.column.title.basic_amount': '基本工资总额',
  'page.cerp.invoice.column.title.contract__end_date': '合同结束日期',
  'page.cerp.invoice.column.title.contract__start_date': '合同起始日期',
  'page.cerp.invoice.column.title.create_time': '创建时间',
  'page.cerp.invoice.column.title.date_submitted': '提交日期',
  'page.cerp.invoice.column.title.email_record__log': '邮件发送日志',
  'page.cerp.invoice.column.title.email_record__status': '邮件发送状态',
  'page.cerp.invoice.column.title.email_record__create_time': '邮件发送时间',
  'page.cerp.invoice.column.title.email_record__update_time': '邮件更新时间',
  'page.cerp.invoice.column.title.excel_files_json': 'Excel',
  'page.cerp.payslip.column.title.exchange_rate': '汇率',
  'page.cerp.invoice.column.title.files_json': 'PDF',
  'page.cerp.invoice.column.title.id': 'ID',
  'page.cerp.invoice.column.title.index': '序号',
  'page.cerp.invoice.column.title.insurance_fee': '保险费',
  'page.cerp.invoice.column.title.invoice_end_date': '请款截止日期',
  'page.cerp.invoice.column.title.invoice_no': '请款单号',
  'page.cerp.invoice.column.title.invoice_start_date': '请款起始日期',
  'page.cerp.invoice.column.title.invoice_type': '请款类型',
  'page.cerp.invoice.column.title.is_active': '是否可用',
  'page.cerp.invoice.column.title.management_fee': '管理费',
  'page.cerp.invoice.column.title.overtime': '加班单位',
  'page.cerp.invoice.column.title.overtime_amount': '加班总额',
  'page.cerp.invoice.column.title.payment_term': '付款周期（天）',
  'page.cerp.invoice.column.title.remark': '备注',
  'page.cerp.invoice.column.title.remittance__amount': '汇款凭证项金额',
  'page.cerp.invoice.column.title.remittance__remittance_set__remittance_name': '汇款凭证',
  'page.cerp.invoice.column.title.service_month': '服务月份',
  'page.cerp.invoice.column.title.status': '状态',
  'page.cerp.invoice.column.title.total_amount': '总金额',
  'page.cerp.invoice.column.title.update_time': '更新时间',
  'page.cerp.invoice.column.title.work_unit': '普通工时单位',
  'page.cerp.invoice.form.label.adviser_id': '隶属顾问',
  'page.cerp.invoice.form.label.bank_account_id': '银行账户',
  'page.cerp.invoice.form.label.invoice_type': '请款类型',
  'page.cerp.invoice.form.label.is_active': '是否可用',
  'page.cerp.invoice.form.label.payment_method_id': '付款方式',
  'page.cerp.invoice.form.label.service_month': '服务月份',
  'page.cerp.invoice.form.label.status': '状态',
  'page.cerp.invoice.form.placeholder.adviser_id': '隶属顾问',
  'page.cerp.invoice.form.placeholder.bank_account_id': '请选择银行账户',
  'page.cerp.invoice.form.placeholder.invoice_type': '请选择请款类型',
  'page.cerp.invoice.form.placeholder.is_active': '请选择是否可用',
  'page.cerp.invoice.form.placeholder.is_remittance': '是否创建汇款凭证项',
  'page.cerp.invoice.form.placeholder.payment_method_id': '请填写payment_method',
  'page.cerp.invoice.form.placeholder.service_month': '请填写开始时间',
  'page.cerp.invoice.form.placeholder.status': '请选择状态',
  'page.cerp.nextofkin.column.title.action': '操作',
  'page.cerp.nextofkin.column.title.adviser__full_name': '隶属顾问',
  'page.cerp.nextofkin.column.title.contact_number': '联系电话',
  'page.cerp.nextofkin.column.title.create_time': '创建时间',
  'page.cerp.nextofkin.column.title.email': '联系邮箱',
  'page.cerp.nextofkin.column.title.id': 'ID',
  'page.cerp.nextofkin.column.title.index': '序号',
  'page.cerp.nextofkin.column.title.is_active': '是否可用',
  'page.cerp.nextofkin.column.title.name': '姓名',
  'page.cerp.nextofkin.column.title.releationship': '关系',
  'page.cerp.nextofkin.column.title.update_time': '更新时间',
  'page.cerp.nextofkin.form.label.adviser__full_name': '隶属顾问',
  'page.cerp.nextofkin.form.label.adviser_id': '隶属顾问',
  'page.cerp.nextofkin.form.label.contact_number': '联系电话',
  'page.cerp.nextofkin.form.label.email': '联系邮箱',
  'page.cerp.nextofkin.form.label.is_active': '是否可用',
  'page.cerp.nextofkin.form.label.name': '姓名',
  'page.cerp.nextofkin.form.label.releationship': '关系',
  'page.cerp.nextofkin.form.label.search_text': '模糊搜索',
  'page.cerp.nextofkin.form.placeholder.adviser_id': '隶属顾问',
  'page.cerp.nextofkin.form.placeholder.contact_number': '请填写联系电话',
  'page.cerp.nextofkin.form.placeholder.email': '请填写联系邮箱',
  'page.cerp.nextofkin.form.placeholder.is_active': '请选择是否可用',
  'page.cerp.nextofkin.form.placeholder.name': '请填写姓名',
  'page.cerp.nextofkin.form.placeholder.releationship': '请填写关系',
  'page.cerp.nextofkin.form.placeholder.search_text': '请填写搜索内容',
  'page.cerp.note.column.title.action': '操作',
  'page.cerp.note.column.title.create_time': '创建时间',
  'page.cerp.note.column.title.date': '日期',
  'page.cerp.note.column.title.id': 'ID',
  'page.cerp.note.column.title.index': '序号',
  'page.cerp.note.column.title.is_active': '是否可用',
  'page.cerp.note.column.title.note': '记录',
  'page.cerp.note.column.title.note_type__name': '记录类型',
  'page.cerp.note.column.title.note_type_id': '记录类型',
  'page.cerp.note.column.title.object_id': '描述',
  'page.cerp.note.column.title.update_time': '更新时间',
  'page.cerp.note.form.label.date': '日期',
  'page.cerp.note.form.label.note': '记录',
  'page.cerp.note.form.label.note_type_id': '记录类型',
  'page.cerp.note.form.label.object_id': '描述',
  'page.cerp.note.form.placeholder.date': '请填写日期',
  'page.cerp.note.form.placeholder.note': '请填写记录',
  'page.cerp.note.form.placeholder.note_type_id': '请选择记录类型',
  'page.cerp.note.form.placeholder.object_id': '请填写描述',
  'page.cerp.payslip.column.title.action': '操作',
  'page.cerp.payslip.column.title.adviser__full_name': '顾问',
  'page.cerp.payslip.column.title.bank_charge_cost': '银行转账费',
  'page.cerp.payslip.column.title.create_time': '创建时间',
  'page.cerp.payslip.column.title.to_do_num': '代办未完成',
  'page.cerp.payslip.column.title.pay_together_status': '合并付款状态',
  'page.cerp.payslip.column.title.date': '顾问款项到账日期',
  'page.cerp.payslip.column.title.email_record__log': '邮件发送日志',
  'page.cerp.payslip.column.title.email_record__status': '邮件发送状态',
  'page.cerp.payslip.column.title.email_record__create_time': '邮件发送时间',
  'page.cerp.payslip.column.title.invoice__remittance__remittance_set__remittance_name':
    '所属remittance',
  'page.cerp.payslip.column.title.check_value': '核验',
  'page.cerp.payslip.column.title.excel_files_json': 'Excel',
  'page.cerp.payslip.column.title.expenses': '报销金额',
  'page.cerp.payslip.column.title.files_json': 'PDF',
  'page.cerp.payslip.column.title.id': 'ID',
  'page.cerp.payslip.column.title.index': '序号',
  'page.cerp.payslip.column.title.insurance_fee': '保险费',
  'page.cerp.payslip.column.title.invoice__invoice_no': '请款单',
  'page.cerp.payslip.column.title.adviser__employee_number': '工号',
  'page.cerp.payslip.column.title.invoice_type': '请款类型',
  'page.cerp.payslip.column.title.is_active': '是否可用',
  'page.cerp.payslip.column.title.payment_status': '付款状态',
  'page.cerp.payslip.column.title.is_valid': '是否有效',
  'page.cerp.payslip.column.title.management_fee': '管理费',
  'page.cerp.payslip.column.title.payment_method_id': '付款方式',
  'page.cerp.payslip.column.title.referral_fees': '推荐费',
  'page.cerp.payslip.column.title.remark': '工资单备注',
  'page.cerp.payslip.column.title.status': '状态',
  'page.cerp.payslip.column.title.total_amount': '总金额',
  'page.cerp.payslip.column.title.founder': '创建人', // '创建人',
  'page.cerp.payslip.column.title.reviser': '修改人', // '修改人',
  'page.cerp.payslip.column.title.transaction__name': '银行流水',
  'page.cerp.payslip.column.title.update_time': '更新时间',
  'page.cerp.payslip.column.title.pay_currency__currency': '付款币种',
  'page.cerp.payslip.column.title.invoice__contract__local_pay_currency__currency': '本地币种',
  'page.cerp.payslip.column.title.cc_bank_account__account_flag': 'CC付款账户',
  'page.cerp.payslip.form.label.adviser__full_name': '顾问',
  'page.cerp.payslip.form.label.adviser_id': '隶属顾问',
  'page.cerp.payslip.form.label.bank_charge_cost': '银行转账费',
  'page.cerp.payslip.form.label.custom_4': '自定义4',
  'page.cerp.payslip.form.label.date': '收到日期',
  'page.cerp.payslip.form.label.deduct_details': '额外抵扣细节',
  'page.cerp.payslip.form.label.deduct_value': '额外抵扣金额',
  'page.cerp.payslip.form.label.eraning_details': '额外收入细节',
  'page.cerp.payslip.form.label.eraning_value': '额外收入金额',
  'page.cerp.payslip.form.label.expenses': '报销金额',
  'page.cerp.payslip.form.label.insurance_fee': '保险费',
  'page.cerp.payslip.form.label.invoice_id': '请款单',
  'page.cerp.payslip.form.label.is_active': '是否可用',
  'page.cerp.payslip.form.label.payment_method_id': '付款方式',
  'page.cerp.payslip.form.label.referral_fees': '推荐费',
  'page.cerp.payslip.form.label.remark': '备注',
  'page.cerp.payslip.form.label.status': '状态',
  'page.cerp.payslip.form.label.transaction_id': '银行流水',
  'page.cerp.payslip.form.placeholder.adviser__full_name': '请选择隶属顾问',
  'page.cerp.payslip.form.placeholder.adviser_id': '请选择隶属顾问',
  'page.cerp.payslip.form.placeholder.bank_charge_cost': '请填写银行转账费',
  'page.cerp.payslip.form.placeholder.custom_4': '请填写自定义4',
  'page.cerp.payslip.form.placeholder.date': '请填写收到日期',
  'page.cerp.payslip.form.placeholder.deduct_details': '请填写额外抵扣细节',
  'page.cerp.payslip.form.placeholder.deduct_value': '请填写额外抵扣金额',
  'page.cerp.payslip.form.placeholder.eraning_details': '请填写额外收入细节',
  'page.cerp.payslip.form.placeholder.eraning_value': '请填写额外收入金额',
  'page.cerp.payslip.form.placeholder.expenses': '请填写报销金额',
  'page.cerp.payslip.form.placeholder.insurance_fee': '请填写保险费',
  'page.cerp.payslip.form.placeholder.invoice_id': '请选择请款单',
  'page.cerp.payslip.form.placeholder.is_active': '请选择是否可用',
  'page.cerp.payslip.form.placeholder.payment_method_id': '请填写付款方式',
  'page.cerp.payslip.form.placeholder.referral_fees': '请填写推荐费',
  'page.cerp.payslip.form.placeholder.remark': '请填写备注',
  'page.cerp.payslip.form.placeholder.status': '请选择状态',
  'page.cerp.payslip.form.placeholder.transaction_id': '请选择银行流水',
  'page.cerp.personreferencenumber.column.title.action': '操作',
  'page.cerp.personreferencenumber.column.title.adviser__full_name': '隶属顾问',
  'page.cerp.personreferencenumber.column.title.create_time': '创建时间',
  'page.cerp.personreferencenumber.column.title.description': '描述',
  'page.cerp.personreferencenumber.column.title.end_date': '截止时间',
  'page.cerp.personreferencenumber.column.title.id': 'ID',
  'page.cerp.personreferencenumber.column.title.index': '序号',
  'page.cerp.personreferencenumber.column.title.is_active': '是否可用',
  'page.cerp.personreferencenumber.column.title.name': '名称',
  'page.cerp.personreferencenumber.column.title.reference_number_type__name': '参考号类别',
  'page.cerp.personreferencenumber.column.title.appendix_json_list': '附件',
  'page.cerp.personreferencenumber.column.title.start_date': '开始时间',
  'page.cerp.personreferencenumber.column.title.update_time': '更新时间',
  'page.cerp.personreferencenumber.form.label.adviser__full_name': '隶属顾问',
  'page.cerp.personreferencenumber.form.label.adviser_id': '隶属顾问',
  'page.cerp.personreferencenumber.form.label.description': '描述',
  'page.cerp.personreferencenumber.form.label.end_date': '截止时间',
  'page.cerp.personreferencenumber.form.label.is_active': '是否可用',
  'page.cerp.personreferencenumber.form.label.name': '名称',
  'page.cerp.personreferencenumber.form.label.reference_number_type_id': '参考号类别',
  'page.cerp.personreferencenumber.form.label.search_text': '模糊搜索',
  'page.cerp.personreferencenumber.form.label.start_date': '开始时间',
  'page.cerp.personreferencenumber.form.placeholder.adviser_id': '隶属顾问',
  'page.cerp.personreferencenumber.form.placeholder.description': '请填写描述',
  'page.cerp.personreferencenumber.form.placeholder.end_date': '请填写截止时间',
  'page.cerp.personreferencenumber.form.placeholder.is_active': '请选择是否可用',
  'page.cerp.personreferencenumber.form.placeholder.name': '请填写名称',
  'page.cerp.personreferencenumber.form.placeholder.reference_number_type_id': '请选择参考号类别',
  'page.cerp.personreferencenumber.form.placeholder.search_text': '请填写搜索内容',
  'page.cerp.personreferencenumber.form.placeholder.start_date': '请填写开始时间',
  'page.cerp.remittance.column.title.action': '操作',
  'page.cerp.remittance.column.title.local_solution': '本地解决方案',
  'page.cerp.remittance.column.title.adviser__full_name': '顾问名称',
  'page.cerp.remittance.column.title.amount': '金额',
  'page.cerp.remittance.column.title.cc_bank_account__account_number': '付款账号',
  'page.cerp.remittance.column.title.create_time': '创建时间',
  'page.cerp.remittance.column.title.description': '描述',
  'page.cerp.remittance.column.title.id': 'ID',
  'page.cerp.remittance.column.title.index': '序号',
  'page.cerp.remittance.column.title.invoice_no': '请款单',
  'page.cerp.remittance.column.title.invoice_status': '请款单状态',
  'page.cerp.remittance.column.title.is_active': '是否可用',
  'page.cerp.remittance.column.title.payslip_no': '工资单',
  'page.cerp.remittance.column.title.remittance_set__remittance_name': '汇款凭证',
  'page.cerp.remittance.column.title.remittance_set__total_amount': '汇款凭证总金额',
  'page.cerp.remittance.column.title.transaction_amount': '付款金额',
  'page.cerp.remittance.column.title.update_time': '更新时间',
  'page.cerp.remittance.form.label.amount': '金额',
  'page.cerp.remittance.form.label.invoice_id': '请款单',
  'page.cerp.remittance.form.label.is_active': '状态',
  'page.cerp.remittance.form.label.remittance_set_id': '汇款凭证',
  'page.cerp.remittance.form.placeholder.amount': '请填写金额',
  'page.cerp.remittance.form.placeholder.invoice_id': '请选择请款单',
  'page.cerp.remittance.form.placeholder.is_active': '请选择状态',
  'page.cerp.remittance.form.placeholder.remittance_set_id': '请选择汇款凭证',
  'page.cerp.remittance.form.placeholder.current_amount': '当前金额',
  'page.baseinfo.to_do_event_priority.form.label.name': '描述', // '描述'
  'page.baseinfo.to_do_event_priority.form.label.priority': '优先等级', // '优先等级'
  'page.baseinfo.to_do_event_priority.form.placeholder.priority': '请填写优先等级',
  'page.baseinfo.to_do_event_priority.form.placeholder.name': '请填写描述',
  'page.baseinfo.to_do_event_priority.column.title.index': '序号', // '序号',
  'page.baseinfo.to_do_event_priority.column.title.id': 'ID', // 'ID',
  'page.baseinfo.to_do_event_priority.column.title.name': '描述', // '描述'
  'page.baseinfo.to_do_event_priority.column.title.priority': '优先等级', // '优先等级'
  'page.baseinfo.to_do_event_priority.column.title.is_active': '是否可用', // '是否可用'
  'page.baseinfo.to_do_event_priority.column.title.action': '操作', // '操作',
  'page.cerp.todoevent.column.title.alarm_number': '提醒间隔天数', // '提醒间隔天数',
  'page.cerp.todoevent.column.title.alarm_time': '提醒时间', // '提醒时间',
  'page.cerp.remittanceset.column.title.action': '操作',
  'page.cerp.remittanceset.column.title.create_time': '创建时间',
  'page.cerp.remittanceset.column.title.date_received': '汇款凭证收到日期',
  'page.cerp.remittanceset.column.title.description': '描述',
  'page.cerp.remittanceset.column.title.id': 'ID',
  'page.cerp.remittanceset.column.title.index': '序号',
  'page.cerp.remittanceset.column.title.is_active': '是否可用',
  'page.cerp.remittanceset.column.title.remittance_name': '名称',
  'page.cerp.remittanceset.column.title.total_amount': '总金额',
  'page.cerp.remittanceset.column.title.invoice_total_amount': '请款单总额', // '请款单总额',
  'page.cerp.remittanceset.column.title.transaction__name': '银行流水',
  'page.cerp.remittanceset.column.title.update_time': '更新时间',
  'page.cerp.remittanceset.form.label.date_received': '请款日期',
  'page.cerp.remittanceset.form.label.description': '描述',
  'page.cerp.remittanceset.form.label.is_active': '状态',
  'page.cerp.remittanceset.form.label.remittance_name': '名称',
  'page.cerp.remittanceset.form.label.total_amount': '总金额',
  'page.cerp.remittanceset.form.label.transaction_id': '银行流水',
  'page.cerp.remittanceset.form.label.bank_charge_id': '银行手续费流水',
  'page.cerp.remittanceset.form.placeholder.date_received': '请填写请款日期',
  'page.cerp.remittanceset.form.placeholder.description': '请填描述',
  'page.cerp.remittanceset.form.placeholder.is_active': '请选择状态',
  'page.cerp.remittanceset.form.placeholder.remittance_name': '请填写名称',
  'page.cerp.remittanceset.form.placeholder.total_amount': '请填总金额',
  'page.cerp.remittanceset.form.placeholder.transaction_id': '请选择银行流水',
  'page.cerp.todoevent.column.title.action': '操作',
  'page.cerp.todoevent.column.title.content': '内容',
  'page.cerp.todoevent.column.title.adviser__full_name': '顾问名称',
  'page.cerp.todoevent.column.title.adviser__employee_number': '顾问工号', // '顾问工号',
  'page.cerp.todoevent.column.title.priority': '优先等级',
  'page.cerp.todoevent.column.title.create_time': '创建时间',
  'page.cerp.todoevent.column.title.index': '序号',
  'page.cerp.todoevent.column.title.is_active': '是否可用',
  'page.cerp.todoevent.column.title.is_finished': '是否完成',
  'page.cerp.todoevent.column.title.person_name': '执行人',
  'page.cerp.todoevent.column.title.person_phone': '执行人手机号',
  'page.cerp.todoevent.column.title.title': '标题',
  'page.cerp.todoevent.column.title.update_time': '更新时间',
  'page.cerp.todoevent.form.label.adviser_id': '顾问',
  'page.cerp.todoevent.form.label.content': '内容',
  'page.cerp.todoevent.form.label.is_active': '状态',
  'page.cerp.todoevent.form.label.is_finished': '是否完成',
  'page.cerp.todoevent.form.label.is_asc': '优先级排序',
  'page.cerp.todoevent.form.label.q': '模糊搜索',
  'page.cerp.todoevent.form.label.status': '紧迫状态',
  'page.cerp.todoevent.form.label.title': '标题',
  'page.cerp.todoevent.form.placeholder.adviser_id': '隶属顾问',
  'page.cerp.todoevent.form.placeholder.content': '请填写内容',
  'page.cerp.todoevent.form.placeholder.is_active': '请选择状态',
  'page.cerp.todoevent.form.placeholder.is_finished': '请选择是否完成',
  'page.cerp.todoevent.form.placeholder.q': '请填写搜索内容',
  'page.cerp.todoevent.form.placeholder.status': '请选择紧迫状态',
  'page.cerp.todoevent.form.placeholder.title': '请填写标题',
  'router.title.adviser-manage': '数据管理', // '顾问管理'
  'router.title.cerp-adviser': '顾问', // '顾问'
  'router.title.basic-balance_statement': '余额报表', // '余额报表'
  'router.title.cerp-bankaccount': '顾问银行账户', // '顾问银行账户'
  'router.title.cerp-bankaccount1': '保险公司银行账户', // '保险公司银行账户'
  'router.title.cerp-banktransaction': '银行流水', // '银行流水'
  'router.title.cerp-contract': '合同', // '合同'
  'router.title.cerp-insurance': '保险', // '保险'
  'router.title.cerp-beneficiary': '保险受益人', // '保险受益人'
  'router.title.cerp-remittanceset': '汇款凭证', // '汇款凭证'
  'router.title.cerp-invoice': '请款单', // '请款单'
  'router.title.cerp-payslip': '工资单', // '工资单'
  'router.title.cerp-payslip_report': '工资单报表', // '工资单报表'
  'router.title.cerp-address': '地址管理', // '地址管理'
  'router.title.cerp-nextofkin': '紧急联系人', // '紧急联系人'
  'router.title.cerp-note': '记录', // '记录'
  'router.title.cerp-personreferencenumber': '个人参考号', // '个人参考号'
  'router.title.cerp-todoevent': '待办事项', // '待办事项'
  'router.title.cerp-wechatupdaterecord': '微信小程序数据', // '微信小程序数据'
  'router.title.cerp-extra_field': '增扣提醒', // '赠扣提醒'
  'router.title.cerp-additional_item_abnormal_data': '额外项异常数据', // '额外项异常数据'
  'router.title.cerp-social_insurance': '社保菜单', // '社保菜单'
  'router.title.cerp-tiprecord': '重要信息查看记录', // '重要信息查看记录'
  'page.baseinfo.tag.form.label.name': '名称', // '名称'
  'page.baseinfo.tag.form.placeholder.name': '请填写名称', // '请填写名称'
  'page.cerp.tiprecord.column.title.index': '序号', // '序号',
  'page.cerp.tiprecord.column.title.id': 'ID', // 'ID',
  'page.cerp.tiprecord.column.title.adviser__full_name': '顾问名称', // '顾问名称',
  'page.cerp.tiprecord.column.title.adviser__employee_number': '顾问工号', // '顾问工号',
  'page.cerp.tiprecord.column.title.tip__name': '重要提示名称', // '重要提示名称',
  'page.cerp.tiprecord.column.title.contract__start_date': '合同起始日期', // '合同起始日期',
  'page.cerp.tiprecord.column.title.contract__end_date': '合同结束日期', // '合同结束日期',
  'page.cerp.tiprecord.column.title.is_active': '状态', // '状态',
  'page.cerp.tiprecord.column.title.action': '操作', // '操作',
  'page.cerp.adviser.form.label.user_email_template_id': '用户邮箱模板', // '用户邮箱模板',
  'page.cerp.adviser.form.label.cc_bank_account_id': 'CC银行账户', // 'CC银行账户',
  'page.cerp.adviser.form.label.end_date': '顾问结束日期', // '顾问结束日期',
  'page.cerp.adviser.form.placeholder.pay_currency_id': '请选择付款币种', // '请选择付款币种',
  'page.cerp.adviser.form.placeholder.user_email_template_id': '请选择用户邮箱模板', // '请选择用户邮箱模板',
  'page.cerp.adviser.form.placeholder.cc_bank_account_id': '请选择CC银行账户', // '请选择CC银行账户',
  'page.cerp.adviser.form.placeholder.end_date': '请选择顾问结束日期', // '请选择顾问结束日期',
  序号: '序号',
  ID: 'ID',
  票据种类: '票据种类',
  创建时间: '创建时间',
  修改时间: '修改时间',
  是否可用: '是否可用',
  操作: '操作',
  请款单模板: '请款单模板',
  工资单模板: '工资单模板',

  是否银行账户信息齐全: '是否银行账户信息齐全',
  名称: '名称',
  请填写名称: '请填写名称',
  票据种类名称: '票据种类名称',
  请填写票据种类名称: '请填写票据种类名称',
  描述: '描述',
  请填写描述: '请填写描述',
  新建票据种类: '新建票据种类',
  修改票据种类: '修改票据种类',
  可用: '可用',
  禁用: '禁用',
  全部: '全部',
  票据合同模板名称: '票据合同模板名称',
  请填写票据合同模板名称: '请填写票据合同模板名称',
  模板数据: '模板数据',
  请填写模板数据: '请填写模板数据',
  票据合同模板: '票据合同模板',
  新建票据合同模板: '新建票据合同模板',
  修改票据合同模板: '修改票据合同模板',
  上传附件: '上传附件',
  上传票据合同模板附件: '上传票据合同模板附件',
  上传成功: '上传成功',
  票据模板名称: '票据模板名称',
  请填写票据模板名称: '请填写票据模板名称',
  自定义项数量: '自定义项数量',
  请填写自定义项数量: '请填写自定义项数量',
  票据模板: '票据模板',
  新建票据模板: '新建票据模板',
  修改票据模板: '修改票据模板',
  提示: '提示',
  无对应附件: '无对应附件',
  无对应付款记录: '无对应付款记录',
  上传票据模板附件: '上传票据模板附件',
  发票号: '发票号',
  请填写发票号: '请填写发票号',
  客户类型: '客户类型',
  请选择客户类型: '请选择客户类型',
  客户属性: '客户属性',
  请选择客户属性: '请选择客户属性',
  隶属顾问: '隶属顾问',
  顾问公司: '顾问公司',
  请选择顾问公司: '请选择顾问公司',
  CC公司: 'CC公司',
  合同CC公司: '合同CC公司',
  请选择CC公司: '请选择CC公司',
  是否自动发送付款凭证: '是否自动发送付款凭证',
  请选择是否自动发送付款凭证: '请选择是否自动发送付款凭证',
  是否需要增扣提醒: '是否需要增扣提醒',
  请选择是否需要增扣提醒: '请选择是否需要增扣提醒',
  顾问: '顾问',
  个人: '个人',
  公司: '公司',
  其他: '其他',
  客户管理: '客户管理',
  地址: '地址',
  电话: '电话',
  邮箱: '邮箱',
  备注: '备注',
  修改: '修改',
  修改客户信息: '修改客户信息',
  客户名称: '客户名称',
  请填写备注: '请填写备注',
  请填写邮箱: '请填写邮箱',
  请填写电话: '请填写电话',
  请填写地址: '请填写地址',
  请填写客户名称: '请填写客户名称',
  新建成功: '新建成功',
  创建顾问: '创建顾问',
  '个人邮箱、工作邮箱至少要填写一个': '个人邮箱、工作邮箱 至少要填写一个!',
  创建顾问公司: '创建顾问公司',
  CC银行账户: 'CC银行账户',
  请选择CC银行账户: '请选择CC银行账户',
  请填输入顾问公司名: '请填输入顾问公司名',
  请填输入顾问公司地址: '请填输入顾问公司地址',
  请填输入顾问公司联系方式: '请填输入顾问公司联系方式',
  请填输入VATNo: '请填输入VATNo',
  创建CC公司: '创建CC公司',
  请填写公司名称: '请填写公司名称',
  请选择国家: '请选择国家',
  新建客户: '新建客户',
  客户: '客户',
  请选择客户: '请选择客户',
  请选择票据合同模板: '请选择票据合同模板',
  请选择票据模板: '请选择票据模板',
  请填写CC公司: '请填写CC公司',
  合同起始时间: '合同起始时间',
  合同结束时间: '合同结束时间',
  合同状态: '合同状态',
  票据合同数据: '票据合同数据',
  发票模板: '发票模板',
  CC邮箱: 'CC邮箱',
  请选择CC邮箱: '请选择CC邮箱',
  合同邮件模板: '合同邮件模板',
  发票邮件模板: '发票邮件模板',
  付款凭证邮件模板: '付款凭证邮件模板',
  附件: '附件',
  邮件发送记录: '邮件发送记录',
  邮件发送状态: '邮件发送状态',
  发送成功: '发送成功',
  发送失败: '发送失败',
  已发送: '已发送',
  邮件发送时间: '邮件发送时间',
  付款凭证邮件发送记录: '付款凭证邮件发送记录',
  付款凭证邮件发送状态: '付款凭证邮件发送状态',
  付款凭证邮件发送时间: '付款凭证邮件发送时间',
  CC签署日期: 'CC签署日期',
  客户确认日期: '客户确认日期',
  三方合同上传: '三方合同上传',
  是: '是',
  否: '否',
  双方合同上传: '双方合同上传',
  修改票据合同: '修改票据合同',
  '请选择合同起始-结束日期': '请选择合同起始-结束日期',
  '合同起始-结束日期': '合同起始-结束日期',
  合同邮箱模板: '合同邮箱模板',
  请选择合同邮箱模板: '请选择合同邮箱模板',
  请选择发票邮件模板: '请选择发票邮件模板',
  请选择付款凭证邮件模板: '请选择付款凭证邮件模板',
  签署合同确认邮件: '签署合同确认邮件',
  请选择签署合同确认邮件: '请选择签署合同确认邮件',
  修改成功: '修改成功',
  上传合同附件: '上传合同附件',
  添加成功: '添加成功',
  预览合同PDF: '预览合同PDF',
  操作成功: '操作成功',
  发送邮件: '发送邮件',
  手动发送邮件: '手动发送邮件',
  发送付款凭证邮件: '发送付款凭证邮件',
  确认三方合同上传成功: '确认三方合同上传成功',
  作废三方合同: '作废三方合同',
  双方合同上传确认: '双方合同上传确认',
  作废双方合同: '作废双方合同',
  CC签署: 'CC签署',
  新建发票: '新建发票',
  请选择票据种类: '请选择票据种类',
  请选择票据合同: '请选择票据合同',
  票据合同: '票据合同',
  发票类型: '发票类型',
  开票日期: '开票日期',
  请选择开票日期: '请选择开票日期',
  服务月份: '服务月份',
  服务描述: '服务描述',
  价格: '价格',
  数量: '数量',
  请选择服务月份: '请选择服务月份',
  总金额: '总金额',
  请填写总金额: '请填写总金额',
  币种: '币种',
  请选择币种: '请选择币种',
  发票数据: '发票数据',
  请填写发票数据: '请填写发票数据',
  收取发票: '收取发票',
  开具发票: '开具发票',
  新建票据合同: '新建票据合同',
  付款日期: '付款日期',
  请选择付款日期: '请选择付款日期',
  发票清单: '发票清单',
  付款状态: '付款状态',
  未付款: '未付款',
  已付款: '已付款',
  增扣项类型: '增扣项类型',
  增扣项: '增扣项',
  增扣项值: '增扣项值',
  增扣项状态: '增扣项状态',
  修改发票: '修改发票',
  修改发票信息: '修改发票信息',
  新增发票信息: '新增发票信息',
  上传发票附件: '上传发票附件',
  生成发票PDF: '生成发票PDF',
  预览发票PDF: '预览发票PDF',
  生成付款凭证: '生成付款凭证',
  预览付款凭证: '预览付款凭证',
  无对应PDF: '无对应PDF',
  新建增扣项: '新建增扣项',
  类型: '类型',
  请选择隶属顾问: '请选择隶属顾问',
  增扣类型: '增扣类型',
  自定义项描述: '自定义项描述',
  请填写自定义项描述: '请填写自定义项描述',
  自定义项值: '自定义项值',
  请填写自定义项值: '请填写自定义项值',
  内容: '内容',
  请填写内容: '请填写内容',
  请选择月份: '请选择月份',
  错误: '错误',
  合同无可选月份: '合同无可选月份',
  请款单: '请款单',
  工资单: '工资单',
  扣减: '扣减',
  增补: '增补',
  删除增扣项: '删除增扣项',
  新建付款记录: '新建付款记录',
  发票: '发票',
  请选择发票: '请选择发票',
  请填写付款日期: '请填写付款日期',
  请选择银行流水: '请选择银行流水',
  银行流水: '银行流水',
  新建银行流水: '新建银行流水',
  收到日期: '收到日期',
  请填写收到日期: '请填写收到日期',
  流水种类: '流水种类',
  请选择流水种类: '请选择流水种类',
  创建成功: '创建成功',
  付款记录: '付款记录',
  付款金额: '付款金额',
  付款币种: '付款币种',
  付款时间: '付款时间',
  流水金额: '流水金额',
  流水币种: '流水币种',
  修改付款记录: '修改付款记录',
  添加银行流水: '添加银行流水',
  请填写银行流水: '请填写银行流水',
  请选择薪资币种: '请选择薪资币种',
  日历年份: '日历年份',
  请填写日历年份: '请填写日历年份',
  工时年历: '工时年历',
  修改工时模板: '修改工时模板',
  年份: '年份',
  国家: '国家',
  工时年历详情: '工时年历详情',
  新建工时模板: '新建工时模板',
  工时日历模板: '工时日历模板',
  设定法定节假日: '设定法定节假日',
  删除: '删除',
  工时录入: '工时录入',
  '开始-结束时间': '开始-结束时间',
  '请填写开始-结束时间': '请填写开始-结束时间',
  节日名字: '节日名字',
  请填写节日名字: '请填写节日名字',
  修改法定节假日: '修改法定节假日',
  日期: '日期',
  请填写日期: '请填写日期',
  删除成功: '删除成功',
  请填写工时: '请填写工时',
  工时: '工时',
  是否删除该日法定节假日: '是否删除该日法定节假日?',
  票据管理: '票据管理',
  顾问待签合同: '顾问待签合同',
  顾问合同未签署测试: '顾问合同未签署测试',
  待办事项: '待办事项',
  重要提示缺失: '重要提示缺失',
  顾问合同开始提醒: '顾问合同开始提醒',
  顾问合同结束提示: '顾问合同结束提示',
  缺少三方合同: '缺少三方合同',
  缺少双方合同: '缺少双方合同',
  保险缺失: '保险缺失',
  保险受益人缺失: '保险受益人缺失',
  保险即将过期或已过期: '保险即将过期或已过期',
  '合同截止，保险未截止': '合同截止，保险未截止',
  '小程序/顾问端待审批': '小程序/顾问端待审批',
  小程序银行账户信息更新待审批: '小程序银行账户信息更新待审批',
  顾问银行资料缺失: '顾问银行资料缺失',
  请款超过12天未收到汇款凭证: '请款超过12天未收到汇款凭证',
  中国籍工作中顾问当前合同无小程序附件: '中国籍工作中顾问当前合同无小程序附件',
  顾问合同下月到期提醒: '顾问合同下月到期提醒',
  CC待签署合同: 'CC待签署合同',
  收起: '收起',
  展开: '展开',
  收起展开: '收起展开',
  配置: '配置',
  关闭配置: '关闭配置',
  基本列信息: '基本列信息',
  设为基础配置: '设为基础配置',
  展示自定义列: '展示自定义列',
  显示: '显示',
  隐藏: '隐藏',
  用户: '用户',
  原始: '原始',
  导出Excel: '导出Excel',
  刷新: '刷新',
  密度: '密度',
  默认: '默认',
  中等: '中等',
  紧凑: '紧凑',
  列展示: '列展示',
  列设置: '列设置',
  全屏: '全屏',
  退出全屏: '退出全屏',
  '显示/隐藏操作列': '显示/隐藏操作列',
  合计: '合计',

  标题: '标题',
  优先等级: '优先等级',
  顾问名称: '顾问名称',
  顾问工号: '顾问工号',
  执行人: '执行人',
  执行人手机号: '执行人手机号',
  是否完成: '是否完成',
  更新时间: '更新时间',
  无: '无',
  工作中: '工作中',
  结束: '结束',
  申请签证中: '申请签证中',
  尚未开始: '尚未开始',
  待定: '待定',
  新数据json: '新数据json',
  旧数据json: '旧数据json',
  未审批: '未审批',
  通过: '通过',
  拒绝: '拒绝',
  审批状态: '审批状态',
  modal名称: 'modal名称',
  modal标记: 'modal标记',
  新创建: '新创建',
  已收到请款: '已收到请款',
  已作废: '已作废',
  生成工资单: '生成工资单',
  生成PDF: '生成PDF',
  垫付: '垫付',
  pdf生成状态: 'pdf生成状态',
  未开始: '未开始',
  正在生成: '正在生成',
  失败: '失败',
  成功: '成功',
  pdf生成日志: 'pdf生成日志',
  工资: '工资',
  报销: '报销',
  加班: '加班',
  CC报销: 'CC报销',
  是否删除: '是否删除',
  日薪: '日薪',
  月薪: '月薪',
  年薪: '年薪',
  时薪: '时薪',
  周薪: '周薪',
  需要: '需要',
  顾问支付: '顾问支付',
  顾问公司支付: '顾问公司支付',
  草稿: '草稿',
  发起合同: '发起合同',
  顾问签署: '顾问签署',
  签署完成: '签署完成',
  手动发送: '手动发送',
  '不需要–顾问在本地': '不需要–顾问在本地',
  '不需要–顾问自己有保单': '不需要–顾问自己有保单',
  '不需要–顾问公司保险': '不需要–顾问公司保险',
  静态信息管理: '静态信息管理',
  首页自定义查询看板: '首页自定义查询看板',
  查询: '查询',
  重置: '重置',
  订阅: '订阅',
  请填写: '请填写',
  请选择: '请选择',
  '是否删除此项记录?': '是否删除此项记录?',
  恢复成功: '恢复成功',
  保存成功: '保存成功',
  邮箱模板: '邮箱模板',
  查询表类型: '查询表类型',
  排序: '排序',
  待办: '待办',
  合同: '合同',
  保险: '保险',
  微信小程序: '微信小程序',
  是否定时发送邮件: '是否定时发送邮件',
  首页自定义查询看板管理: '首页自定义查询看板管理',
  新建: '新建',
  请填写查询表类型: '请填写查询表类型',
  请选择请邮箱模板: '请选择请邮箱模板',
  请填写排序: '请填写排序',
  '自动发送邮件频率（天）': '自动发送邮件频率（天）',
  查询代码: '查询代码',
  银行账户: '银行账户',
  银行名称: '银行名称',
  请填写银行名称: '请填写银行名称',
  银行地址: '银行地址',
  请填写银行地址: '请填写银行地址',
  账户代号: '账户代号',
  请填写账户代号: '请填写账户代号',
  新建CC银行账户: '新建CC银行账户',
  修改CC银行账户: '修改CC银行账户',
  币种关联: '币种关联',
  账号: '账号',
  请填输入账号: '请填输入账号',
  添加备注: '添加备注',
  条备注: '条备注',
  关闭: '关闭',
  修改币种关联: '修改币种关联',
  CC公司管理: 'CC公司管理',
  新建CC公司: '新建CC公司',
  修改CC公司: '修改CC公司',
  上传签章模板: '上传签章模板',
  上传工资单模板: '上传工资单模板',
  上传请款单模板: '上传请款单模板',
  上传logo: '上传logo',
  保存: '保存',
  取消: '取消',
  确定上传: '确定上传',
  上传: '上传',
  '只能上传一个模板，默认上传第一个': '只能上传一个模板，默认上传第一个',
  CC公司合同模板: 'CC公司合同模板',
  请款邮箱地址: '请款邮箱地址',
  请选择请款邮箱地址: '请选择请款邮箱地址',
  请款单邮箱模板: '请款单邮箱模板',
  请款时顾问提示邮箱模板: '请款时顾问提示邮箱模板',
  合同模板: '合同模板',
  签署合同确认邮箱模板: '签署合同确认邮箱模板',
  合同到期提醒邮箱模板: '合同到期提醒邮箱模板',
  工资单邮箱模板: '工资单邮箱模板',
  CC报销请款单邮箱模板: 'CC报销请款单邮箱模板',
  报销请款单邮箱模板: '报销请款单邮箱模板',
  加班请款单邮箱模板: '加班请款单邮箱模板',
  CN请款单邮箱模板: 'CN请款单邮箱模板',
  请选择CN请款单邮箱模板: '请选择CN请款单邮箱模板',
  请选择加班请款单邮箱模板: '请选择加班请款单邮箱模板',
  请选择报销请款单邮箱模板: '请选择报销请款单邮箱模板',
  请选择CC报销请款单邮箱模板: '请选择CC报销请款单邮箱模板',
  请选择工资单邮箱模板: '请选择工资单邮箱模板',
  请选择签署合同确认邮箱模板: '请选择签署合同确认邮箱模板',
  请选择合同到期提醒邮箱模板: '请选择合同到期提醒邮箱模板',
  请选择合同模板: '请选择合同模板',
  请选择请款时顾问提示邮箱模板: '请选择请款时顾问提示邮箱模板',
  请选择请款单邮箱模板: '请选择请款单邮箱模板',
  CC公司合同录入模板: 'CC公司合同录入模板',
  请款邮箱模板: '请款邮箱模板',
  cc报销请款单邮箱模板: 'cc报销请款单邮箱模板',
  国家管理: '国家管理',
  新建国家: '新建国家',
  修改国家: '修改国家',
  ID类别: 'ID类别',
  保险公司: '保险公司',
  新建保险公司: '新建保险公司',
  修改保险公司: '修改保险公司',
  点击添加: '点击添加',
  理赔流程: '理赔流程',
  代码内容: '代码内容',
  新增银行账户: '新增银行账户',
  请选择保险公司: '请选择保险公司',
  是否当前账号: '是否当前账号',
  银行英文名称: '银行英文名称',
  请填写开户行地址: '请填写开户行地址',
  账户持有人: '账户持有人',
  开户行地址: '开户行地址',
  请填写账号: '请填写账号',
  请填写账户持有人: '请填写账户持有人',
  请选择银行英文名称: '请选择银行英文名称',
  请选择银行名称: '请选择银行名称',
  请选择预设银行名称: '请选择预设银行名称',
  '预设银行名称(非必选)': '预设银行名称(非必选)',
  记录类型: '记录类型',
  记录类型管理: '记录类型管理',
  新建记录类型: '新建记录类型',
  修改记录类型: '修改记录类型',
  个人类别: '个人类别',
  个人类别管理: '个人类别管理',
  新建个人类别: '新建个人类别',
  修改个人类别: '修改个人类别',
  顾问公司管理: '顾问公司管理',
  新建顾问公司: '新建顾问公司',
  修改顾问公司: '修改顾问公司',
  顾问公司ID: '顾问公司ID',
  请填写顾问公司ID: '请填写顾问公司ID',
  顾问公司名: '顾问公司名',
  请填写顾问公司名: '请填写顾问公司名',
  顾问公司地址: '顾问公司地址',
  请填写顾问公司地址: '请填写顾问公司地址',
  顾问公司联系方式: '顾问公司联系方式',
  请填写顾问公司联系方式: '请填写顾问公司联系方式',
  顾问公司联系人: '顾问公司联系人',
  顾问公司联系人管理: '顾问公司联系人管理',
  新建顾问公司联系人: '新建顾问公司联系人',
  修改顾问公司联系人: '修改顾问公司联系人',
  联系方式: '联系方式',
  请填写联系方式: '请填写联系方式',
  请填写请款单发送邮箱: '请填写请款单发送邮箱',
  请款单发送邮箱: '请款单发送邮箱',
  参考号类别: '参考号类别',
  参考号类别管理: '参考号类别管理',
  新建参考号类别: '新建参考号类别',
  修改参考号类别: '修改参考号类别',
  管理费计算方式: '管理费计算方式',
  修改管理费计算方式: '修改管理费计算方式',
  CC邮箱管理: 'CC邮箱管理',
  修改CC邮箱: '修改CC邮箱',
  文本: '文本',
  html: 'html',
  修改邮箱模板: '修改邮箱模板',
  图片展示: '图片展示',
  未上传图片: '未上传图片',
  上传图片: '上传图片',
  删除图片: '删除图片',
  确认删除图片: '确认删除图片',
  '只能上传一张图片，默认上传第一张': '只能上传一张图片，默认上传第一张',
  收件箱地址管理: '收件箱地址管理',
  数据变动管理: '数据变动管理',
  数据变动: '数据变动',
  确定: '确定',
  选择: '选择',
  修改合同模板: '修改合同模板',
  未上传合同模板: '未上传合同模板',
  上传模板: '上传模板',
  银行管理: '银行管理',
  终端用户: '终端用户',
  待办优先等级: '待办优先等级',
  新建待办优先等级: '新建待办优先等级',
  修改待办优先等级: '修改待办优先等级',
  客服问题管理: '客服问题管理',
  分组名称: '分组名称',
  问题分组管理: '问题分组管理',
  新建分组: '新建分组',
  修改分组: '修改分组',
  问题列表: '问题列表',
  新建客服问题: '新建客服问题',
  修改客服问题: '修改客服问题',
  问题: '问题',
  请填写问题: '请填写问题',
  答案: '答案',
  隶属问题分组: '隶属问题分组',
  请选择隶属问题分组: '请选择隶属问题分组',
  请填写答案: '请填写答案',
  汇率: '汇率',
  修改汇率: '修改汇率',
  合同币种: '合同币种',
  请选择对应币种: '请选择对应币种',
  本地币种: '本地币种',
  请选择本地币种: '请选择本地币种',
  微信小程序主页: '微信小程序主页',
  修改微信小程序主页: '修改微信小程序主页',
  图片: '图片',
  更换图片: '更换图片',
  修改文字: '修改文字',
  请填写文字: '请填写文字',
  文字: '文字',
  标签: '标签',
  请填写标签名称: '请填写标签名称',
  标签管理: '标签管理',
  新建标签: '新建标签',
  公告与重要信息: '公告与重要信息',
  请填写标题: '请填写标题',
  请选择标签: '请选择标签',
  公司公告: '公司公告',
  重要信息: '重要信息',
  已读人员: '已读人员',
  已读数: '已读数',
  未读人员: '未读人员',
  未读数: '未读数',
  '新建公告（重要信息）': '新建公告（重要信息）',
  上传PDF: '上传PDF',
  详情: '详情',
  公告阅读记录: '公告阅读记录',
  公告内容: '公告内容',
  '是否删除?': '是否删除?',
  重要提示: '重要提示',
  新建重要提示: '新建重要提示',
  修改重要提示: '修改重要提示',
  模板: '模板',
  提示内容: '提示内容',
  请填写提示内容: '请填写提示内容',
  入职流程提示: '入职流程提示',
  请填写每月保费: '请填写每月保费',
  每月保费: '每月保费',
  保费成本: '保费成本',
  请填写保费成本: '请填写保费成本',
  保险类型管理: '保险类型管理',
  新建保险类型: '新建保险类型',
  年: '年',
  正序: '正序',
  倒序: '倒序',
  离职: '离职',
  潜在雇员: '潜在雇员',
  顾问管理: '顾问管理',
  新建顾问: '新建顾问',
  修改顾问: '修改顾问',
  请先删除顾问结束日期: '请先删除顾问结束日期',
  删除顾问结束日期: '删除顾问结束日期',
  是否删除顾问结束日期: '是否删除顾问结束日期',
  小程序解绑: '小程序解绑',
  是否小程序解绑: '是否小程序解绑',
  创建账号: '创建账号',
  用户邮箱模板: '用户邮箱模板',
  请选择用户邮箱模板: '请选择用户邮箱模板',
  用户名: '用户名',
  删除账号: '删除账号',
  是否删除账号: '是否删除账号',
  确认发送邮件: '确认发送邮件',
  恢复账号: '恢复账号',
  恢复账号成功: '恢复账号成功',
  重置密码: '重置密码',
  重置成功: '重置成功',
  新顾问: '新顾问',
  '-无-': '-无-',
  详情页: '详情页',
  已绑定微信小程序: '已绑定微信小程序',
  未绑定微信小程序: '未绑定微信小程序',
  新建待办事项: '新建待办事项',
  删除结束时间: '删除结束时间',
  称谓: '称谓',
  手机: '手机',
  微信账号: '微信账号',
  WhatsApp: 'WhatsApp',
  个人状态: '个人状态',
  国籍: '国籍',
  个人邮箱: '个人邮箱',
  QQ账号: 'QQ账号',
  保险信息: '保险信息',
  出生日期: '出生日期',
  工作邮箱: '工作邮箱',
  其他名字: '其他名字',
  顾问结束日期: '顾问结束日期',
  顾问账号名: '顾问账号名',
  顾问账号状态: '顾问账号状态',
  cc银行账户: 'cc银行账户',
  家庭地址: '家庭地址',
  工作地址: '工作地址',
  当前合同负责人: '当前合同负责人',
  展开当前合同: '展开当前合同',
  职务: '职务',
  项目名称: '项目名称',
  工作地点: '工作地点',
  合同起始结束日期: '合同起始结束日期',
  基本单位薪资: '基本单位薪资',
  薪资币种: '薪资币种',
  薪资种类: '薪资种类',
  管理费支付方式: '管理费支付方式',
  cc管理费: 'cc管理费',
  保险费支付方式: '保险费支付方式',
  顾问保险费: '顾问保险费',
  顾问公司保险费: '顾问公司保险费',
  银行转账费支付方式: '银行转账费支付方式',
  银行转账费: '银行转账费',
  本地发薪工资: '本地发薪工资',
  是否运用本地解决方案: '是否运用本地解决方案',
  本地管理费: '本地管理费',
  本地扣减费: '本地扣减费',
  增扣提醒: '增扣提醒',
  年假剩余: '年假剩余',
  天: '天',
  已锁定: '已锁定',
  普通工时录入: '普通工时录入',
  加班工时录入: '加班工时录入',
  年假工时录入: '年假工时录入',
  录入: '录入',
  顾问工时: '顾问工时',
  未查到当前顾问信息: '未查到当前顾问信息',
  '顾问工时（锁定后：月工时、月使用年假将不可调整）':
    '顾问工时（锁定后：月工时、月使用年假将不可调整）',
  '本地管理费率（%）': '本地管理费率（%）',
  'cc管理费率（%）': 'cc管理费率（%）',
  '不需要-顾问公司支付': '不需要-顾问公司支付',
  '结束，不需要': '结束，不需要',
  '年假剩余天数为0，不可再增加！': '年假剩余天数为0，不可再增加！',
  顾问信息: '顾问信息',
  正常: '正常',
  受益人: '受益人',
  银行账户信息: '银行账户信息',
  个人参考号: '个人参考号',
  紧急联系人: '紧急联系人',
  新增合同: '新增合同',
  保险类型: '保险类型',
  姓名: '姓名',
  新建工作地址: '新建工作地址',
  新建家庭地址: '新建家庭地址',
  新建地址: '新建地址',
  请选择地址: '请选择地址',
  请填写隶属顾问: '请填写隶属顾问',
  街道: '街道',
  请填写街道: '请填写街道',
  城市: '城市',
  请填写城市: '请填写城市',
  '州/省': '州/省',
  '请填写州/省': '请填写州/省',
  邮编: '邮编',
  请填写邮编: '请填写邮编',
  地址类型: '地址类型',
  修改工作地址: '修改工作地址',
  修改家庭地址: '修改家庭地址',
  修改顾问信息: '修改顾问信息',
  请填写用户名: '请填写用户名',
  是否恢复账号: '是否恢复账号',
  是否重置密码: '是否重置密码',
  解绑成功: '解绑成功',
  提醒时间: '提醒时间',
  提醒间隔天数: '提醒间隔天数',
  请填写提醒间隔天数: '请填写提醒间隔天数',
  请填写优先等级: '请填写优先等级',
  完成: '完成',
  未完成: '未完成',
  新合同: '新合同',
  修改合同: '修改合同',
  浏览合同: '浏览合同',
  请选择当前合同负责人: '请选择当前合同负责人',
  是否当前合同: '是否当前合同',
  请填写合同起始结束日期: '请填写合同起始结束日期',
  请填写本地发薪工资: '请填写本地发薪工资',
  请填写基本单位薪资: '请填写基本单位薪资',
  请填写住宿费率: '请填写住宿费率',
  住宿费率: '住宿费率',
  请填写cc管理费: '请填写cc管理费',
  请填写本地管理费: '请填写本地管理费',
  顾问转账费: '顾问转账费',
  请填写顾问转账费: '请填写顾问转账费',
  请选择保险类型: '请选择保险类型',
  提醒天数: '提醒天数',
  请填写提醒天数: '请填写提醒天数',
  请选择终端用户: '请选择终端用户',
  男: '男',
  女: '女',
  加班费率: '加班费率',
  请填写加班费率: '请填写加班费率',
  请选择工作地点: '请选择工作地点',
  请填写cc管理费率: '请填写cc管理费率',
  请填写本地管理费率: '请填写本地管理费率',
  请填写银行转账费: '请填写银行转账费',
  顾问公司转账费: '顾问公司转账费',
  请填写顾问公司转账费: '请填写顾问公司转账费',
  合同补充条款: '合同补充条款',
  请填写合同补充条款: '请填写合同补充条款',
  请填写职务: '请填写职务',
  合同发送抄送邮箱: '合同发送抄送邮箱',
  请选择合同发送抄送邮箱: '请选择合同发送抄送邮箱',
  新建收件箱: '新建收件箱',
  邮箱名称: '邮箱名称',
  请填写邮箱名称: '请填写邮箱名称',
  邮箱地址: '邮箱地址',
  请填写邮箱地址: '请填写邮箱地址',
  请选择薪资种类: '请选择薪资种类',
  个税代缴: '个税代缴',
  请填写本地扣减费: '请填写本地扣减费',
  请填写顾问保险费: '请填写顾问保险费',
  全时约当数: '全时约当数',
  请填写全时约当数: '请填写全时约当数',
  请填写项目名称: '请填写项目名称',
  请款抄送邮箱地址: '请款抄送邮箱地址',
  请选择顾问公司联系人: '请选择顾问公司联系人',
  请选择请款抄送邮箱地址: '请选择请款抄送邮箱地址',
  工时确认邮箱模板: '工时确认邮箱模板',
  请选择工时确认邮箱模板: '请选择工时确认邮箱模板',
  本地发薪数值: '本地发薪数值',
  请填写本地发薪数值: '请填写本地发薪数值',
  社保代缴: '社保代缴',
  本地发薪币种: '本地发薪币种',
  请选择本地发薪币种: '请选择本地发薪币种',
  请选择管理费计算方式: '请选择管理费计算方式',
  管理费描述: '管理费描述',
  请填写管理费描述: '请填写管理费描述',
  年假天数: '年假天数',
  请填写年假天数: '请填写年假天数',
  请填写顾问公司保险费: '请填写顾问公司保险费',
  转为当前合同: '转为当前合同',
  是否转为当前合同: '是否转为当前合同',
  上传小程序附件: '上传小程序附件',
  创建合同草稿: '创建合同草稿',
  确认创建合同草稿: '确认创建合同草稿',
  请选择是否运用本地解决方案: '请选择是否运用本地解决方案',
  合同预览: '合同预览',
  发送合同: '发送合同',
  没有可预览的合同: '没有可预览的合同',
  确认发送当前合同: '确认发送当前合同',
  手动发送合同: '手动发送合同',
  确认手动发送当前合同: '确认手动发送当前合同',
  手动发送当前合同成功: '手动发送当前合同成功',
  三方合同上传确认: '三方合同上传确认',
  确认已上传三方合同: '确认已上传三方合同',
  确认已上传双方合同: '确认已上传双方合同',
  三方合同作废: '三方合同作废',
  确认双方合同上传成功: '确认双方合同上传成功',
  确认作废双方合同: '确认作废双方合同',
  签署合同: '签署合同',
  双方合同作废: '双方合同作废',
  确认签署合同: '确认签署合同',
  签署成功: '签署成功',
  创建顾问全部工时: '创建顾问全部工时',
  确认创建顾问全部工时: '确认创建顾问全部工时',
  创建顾问全部工时成功: '创建顾问全部工时成功',
  更新顾问月工时: '更新顾问月工时',
  工时月份: '工时月份',
  请选择工时月份: '请选择工时月份',
  顾问当月工时已锁定: '顾问当月工时已锁定',
  终止合同: '终止合同',
  终止日期: '终止日期',
  请选择终止日期: '请选择终止日期',
  终止合同成功: '终止合同成功',
  删除合同终止日期: '删除合同终止日期',
  是否删除合同终止日期: '是否删除合同终止日期',
  复制: '复制',
  复制新增合同: '复制新增合同',
  '无当前合同，请新增': '无当前合同，请新增',
  '是否复制当前的合同？   如果放弃创建合同请选择【新建】，然后【取消】':
    '是否复制当前的合同？   如果放弃创建合同请选择【新建】，然后【取消】',
  保险单号: '保险单号',
  请填写保险单号: '请填写保险单号',
  起保日期时间: '起保日期时间',
  请填写起保日期时间: '请填写起保日期时间',
  停保日期时间: '停保日期时间',
  请填写停保日期时间: '请填写停保日期时间',
  当前保险: '当前保险',
  请填写当前保险: '请填写当前保险',
  新建保险: '新建保险',
  修改保险: '修改保险',
  保险管理: '保险管理',
  保单: '保单',
  其他附件: '其他附件',
  请填写起停保日期时间: '请填写起停保日期时间',
  '起保-停保日期时间': '起保-停保日期时间',
  新建受益人: '新建受益人',
  请选择受益人: '请选择受益人',
  请填写姓名: '请填写姓名',
  性别: '性别',
  请选择证件类别: '请选择证件类别',
  证件类别: '证件类别',
  请填写出生日期: '请填写出生日期',
  证件号: '证件号',
  请填写证件号: '请填写证件号',
  请填写证件有效期: '请填写证件有效期',
  证件有效期: '证件有效期',
  请填输入姓名: '请填输入姓名',
  请填输入性别: '请填输入性别',
  请填输入出生日期: '请填输入出生日期',
  保单上传: '保单上传',
  请填输入证件号: '请填输入证件号',
  其他附件上传: '其他附件上传',
  '当前保险,不可删除': '当前保险,不可删除',
  确认删除保险: '确认删除保险',
  是否复制当前的保险: '是否复制当前的保险',
  请填输入起停保日期时间: '请填输入起停保日期时间',
  请填输入当前保险: '请填输入当前保险',
  请填输入每月保费: '请填输入每月保费',
  新增保险: '新增保险',
  '无当前保险，请新增': '无当前保险，请新增',
  本人: '本人',
  配偶: '配偶',
  子女: '子女',
  父母: '父母',
  修改受益人: '修改受益人',
  证件类别名: '证件类别名',
  受益比例: '受益比例',
  与被保险人关系: '与被保险人关系',
  新增受益人: '新增受益人',
  请选择与被保险人关系: '请选择与被保险人关系',
  请填写受益比例: '请填写受益比例',
  修改银行账户: '修改银行账户',
  请选择银行: '请选择银行',
  开户时向银行提供的住址: '开户时向银行提供的住址',
  请填写开户时向银行提供的住址: '请填写开户时向银行提供的住址',
  请填输入银行名称: '请填输入银行名称',
  请填输入账户持有人: '请填输入账户持有人',
  请填输入开户行地址: '请填输入开户行地址',
  请填输入是否当前账号: '请填输入是否当前账号',
  月份: '月份',
  月工时: '月工时',
  当月使用年假: '当月使用年假',
  年假余额: '年假余额',
  是否锁定: '是否锁定',
  合同起始日期: '合同起始日期',
  合同结束日期: '合同结束日期',
  PDF生成日志: 'PDF生成日志',
  锁定: '锁定',
  锁定成功: '锁定成功',
  取消锁定: '取消锁定',
  是否取消锁定: '是否取消锁定',
  取消锁定成功: '取消锁定成功',
  生成顾问月工时PDF: '生成顾问月工时PDF',
  是否生成顾问月工时PDF: '是否生成顾问月工时PDF',
  发送顾问月工时邮件: '发送顾问月工时邮件',
  是否发送顾问月工时邮件: '是否发送顾问月工时邮件',
  修改顾问工时: '修改顾问工时',
  请填写月份: '请填写月份',
  当月工时数: '当月工时数',
  请填写当月工时数: '请填写当月工时数',
  当月年假天数: '当月年假天数',
  请填写当月年假天数: '请填写当月年假天数',
  请款单号: '请款单号',
  工号: '工号',
  状态: '状态',
  报销已入账: '报销已入账',
  邮件发送日志: '邮件发送日志',
  请款类型: '请款类型',
  邮件更新时间: '邮件更新时间',
  汇款凭证: '汇款凭证',
  汇款凭证项金额: '汇款凭证项金额',
  请款起始日期: '请款起始日期',
  提交日期: '提交日期',
  普通工时单位: '普通工时单位',
  基本工资总额: '基本工资总额',
  宿款单位: '宿款单位',
  住宿总额: '住宿总额',
  加班单位: '加班单位',
  加班总额: '加班总额',
  管理费: '管理费',
  保险费: '保险费',
  计算公式备注: '计算公式备注',
  '付款周期（天）': '付款周期（天）',
  CC收款账户: 'CC收款账户',
  创建人: '创建人',
  修改人: '修改人',
  预览: '预览',
  工时证明文件: '工时证明文件',
  是否生成请款单PDF: '是否生成请款单PDF',
  生成请款单PDF成功: '生成请款单PDF成功',
  新建工资单: '新建工资单',
  双方合同没有确认上传: '双方合同没有确认上传',
  新建垫付工资单: '新建垫付工资单',
  工时证明文件上传: '工时证明文件上传',
  有工时证明文件一并发出: '有工时证明文件一并发出',
  是否手动发送: '是否手动发送',
  是否发送: '是否发送',
  重新发送: '重新发送',
  是否重新发送: '是否重新发送',
  重新发送成功: '重新发送成功',
  新增汇款凭证项: '新增汇款凭证项',
  请选择汇款凭证: '请选择汇款凭证',
  请填描述: '请填描述',
  金额: '金额',
  请填总金额: '请填总金额',
  修改请款单管理费: '修改请款单管理费',
  请填写请款单号: '请填写请款单号',
  请填写管理费: '请填写管理费',
  重新生成工资单: '重新生成工资单',
  是否删除请款单: '是否删除请款单',
  是否恢复请款单: '是否恢复请款单',
  新建请款单: '新建请款单',
  请款日期: '请款日期',
  请填写请款起始日期: '请填写请款起始日期',
  请选择请款类型: '请选择请款类型',
  请填写普通工时单位: '本地发薪工资',
  请填写基本工资总额: '请填写基本工资总额',
  请填写计算公式备注: '请填写计算公式备注',
  请选择合同: '请选择合同',
  请填写服务月份: '请填写服务月份',
  请填写加班单位: '请填写加班单位',
  请填写加班总额: '请填写加班总额',
  CC银行收款账户: 'CC银行收款账户',
  付款周期: '付款周期',
  请填写付款周期: '请填写付款周期',
  请填写保险费: '请填写保险费',
  请选择银行收款账户: '请选择银行收款账户',
  请填写宿款单位: '请填写宿款单位',
  请填写住宿总额: '请填写住宿总额',
  请款回执是否发送顾问: '请款回执是否发送顾问',
  请选择CC银行收款账户: '请选择CC银行收款账户',
  服务月份有误: '服务月份有误',
  无对应合同: '无对应合同',
  显示到工资单: '显示到工资单',
  请款单自定义项最多6个: '请款单自定义项最多6个',
  自定义项达到最大数量: '自定义项达到最大数量',
  '当前已有请款单,是否创建另一个?': '当前已有请款单,是否创建另一个?',
  '顾问无CC公司当前合同，请创建': '顾问无CC公司当前合同，请创建',
  '其他已删除相关联的汇款凭证项、工资单、等信息不会恢复':
    '其他已删除相关联的汇款凭证项、工资单、等信息不会恢复',
  '可能会删除相关联的汇款凭证项、工资单、等信息': '可能会删除相关联的汇款凭证项、工资单、等信息',
  已确认: '已确认',
  未收到款项: '未收到款项',
  收到款项: '收到款项',
  应付款金额: '应付款金额',
  付款银行流水: '付款银行流水',
  工资单银行流水: '工资单银行流水',
  付款月份: '付款月份',
  所属remittance: '所属remittance',
  核验: '核验',
  CC付款账户: 'CC付款账户',
  推荐费: '推荐费',
  合同薪资币种: '合同薪资币种',
  合并付款状态: '合并付款状态',
  待办未完成: '待办未完成',
  同下次薪资合并: '同下次薪资合并',
  暂且不付: '暂且不付',
  分开支付: '分开支付',
  合并所有历史付款: '合并所有历史付款',
  是否有效: '是否有效',
  有效: '有效',
  无效: '无效',
  添加额外项: '添加额外项',
  请填写推荐费: '请填写推荐费',
  请选择CC付款账户: '请选择CC付款账户',
  请选择合同薪资币种: '请选择合同薪资币种',
  邮件是否自动发送: '邮件是否自动发送',
  请填写邮件发送时间: '请填写邮件发送时间',
  '无对应合同，请创建!': '无对应合同，请创建!',
  请选择付款币种: '请选择付款币种',
  额外项描述: '额外项描述',
  额外项值: '额外项值',
  工资单自定义项最多9个: '工资单自定义项最多9个',
  知道了: '知道了',
  顾问公司名称: '顾问公司名称',
  请款截止日期: '请款截止日期',
  汇款金额: '汇款金额',
  工资单号: '工资单号',
  基本薪资单位: '基本薪资单位',
  住宿单位: '住宿单位',
  '工资单 # ': '工资单 # ',
  '请款单 # ': '请款单 # ',
  '本地管理费对应汇率（本地:合同）': '本地管理费对应汇率（本地:合同）',
  '邮件发送时间（n天后）': '邮件发送时间（n天后）',
  '付款（银行流水）': '付款（银行流水）',
  付款: '付款',
  请填输入名称: '请填输入名称',
  收入: '收入',
  支出: '支出',
  付款成功: '付款成功',
  取消付款: '取消付款',
  是否取消付款: '是否取消付款',
  取消付款成功: '取消付款成功',
  '0付款': '0付款',
  '0付款成功': '0付款成功',
  是否生成工资单PDF: '是否生成工资单PDF',
  生成工资单PDF成功: '生成工资单PDF成功',
  是否发送邮件: '是否发送邮件',
  发送邮件成功: '发送邮件成功',
  重新发送邮件: '重新发送邮件',
  是否重新发送邮件: '是否重新发送邮件',
  重新发送邮件成功: '重新发送邮件成功',
  确认收到: '确认收到',
  是否确认收到工资单: '是否确认收到工资单',
  工资单确认成功: '工资单确认成功',
  修改工资单管理费: '修改工资单管理费',
  请选择合并付款状态: '请选择合并付款状态',
  修改合并付款状态: '修改合并付款状态',
  修改付款月份: '修改付款月份',
  请填写付款月份: '请填写付款月份',
  开始时间: '开始时间',
  截止时间: '截止时间',
  修改个人参考号: '修改个人参考号',
  '开始-截止时间': '开始-截止时间',
  修改付款月份成功: '修改付款月份成功',
  请选择参考号类别: '请选择参考号类别',
  新增个人参考号: '新增个人参考号',
  新增成功: '新增成功',
  关系: '关系',
  请填写关系: '请填写关系',
  联系电话: '联系电话',
  请填写联系电话: '请填写联系电话',
  联系邮箱: '联系邮箱',
  请填写联系邮箱: '请填写联系邮箱',
  请填输入关系: '请填输入关系',
  记录: '记录',
  请填写记录对象ID: '请填写记录对象ID',
  记录对象ID: '记录对象ID',
  请选择记录类型: '请选择记录类型',
  请填写记录: '请填写记录',
  备注类型: '备注类型',
  请选择优先等级: '请选择优先等级',
  请填写提醒时间: '请填写提醒时间',
  修改待办事项: '修改待办事项',
  请填输入标题: '请填输入标题',
  普通: '普通',
  紧急: '紧急',
  新建增扣提醒: '新建增扣提醒',
  请选择类型: '请选择类型',
  余额表: '余额表',
  应付: '应付',
  总额: '总额',
  可用余额: '可用余额',
  真实余额: '真实余额',
  时间区间: '时间区间',
  请选择合同本地发薪币种: '请选择合同本地发薪币种',
  合同本地发薪币种: '合同本地发薪币种',
  工资单报表: '工资单报表',
  银行账户信息管理: '银行账户信息管理',
  请填写顾问工号: '请填写顾问工号',
  是否未添加CC银行账户: '是否未添加CC银行账户',
  是否未添加币种: '是否未添加币种',
  请填输入流水种类: '请填输入流水种类',
  银行流水管理: '银行流水管理',
  修改银行流水: '修改银行流水',
  无对应项: '无对应项',
  合同管理: '合同管理',
  创建合同草稿成功: '创建合同草稿成功',
  发送当前合同成功: '发送当前合同成功',
  受益人管理: '受益人管理',
  新建汇款凭证: '新建汇款凭证',
  修改汇款凭证: '修改汇款凭证',
  汇款凭证项: '汇款凭证项',
  批量创建工资单: '批量创建工资单',
  当前已生成的工资单不会重新创建: '当前已生成的工资单不会重新创建',
  工资单批量创建成功: '工资单批量创建成功',
  未选择汇款凭证项: '未选择汇款凭证项',
  请选择需要创建工资单的汇款凭证项: '请选择需要创建工资单的汇款凭证项',
  汇款收到日期: '汇款收到日期',
  请填写汇款收到日期: '请填写汇款收到日期',
  银行手续费流水: '银行手续费流水',
  请填写银行手续费流水: '请填写银行手续费流水',
  请填输入总金额: '请填输入总金额',
  添加手续费银行流水: '添加手续费银行流水',
  跳转工资单: '跳转工资单',
  汇款凭证项批量导入: '汇款凭证项批量导入',
  全选: '全选',
  选中: '选中',
  请款单列表: '请款单列表',
  未找到相关请款单: '未找到相关请款单',
  查找请款单: '查找请款单',
  创建汇款凭证项: '创建汇款凭证项',
  合同信息: '合同信息',
  请款单总额: '请款单总额',
  汇款凭证名称: '汇款凭证名称',
  请款单状态: '请款单状态',
  是否已关联汇款凭证项: '是否已关联汇款凭证项',
  工资单展示: '工资单展示',
  批量生成PDF: '批量生成PDF',
  批量发送邮件: '批量发送邮件',
  查询请款单: '查询请款单',
  PDF生成失败: 'PDF生成失败',
  确定生成PDF: '确定生成PDF',
  工资单批量生成PDF成功: '工资单批量生成PDF成功',
  汇款凭证项创建成功: '汇款凭证项创建成功',
  PDF生成成功: 'PDF生成成功',
  邮件发送成功: '邮件发送成功',
  确定批量发送邮件: '确定批量发送邮件',
  批量发送邮件成功: '批量发送邮件成功',
  是否删除工资单: '是否删除工资单',
  修改工资单管理费成功: '修改工资单管理费成功',
  汇款凭证项批量创建: '汇款凭证项批量创建',
  是否删除此项记录: '是否删除此项记录',
  '无对应请款单，无法创建': '无对应请款单，无法创建',
  '汇款凭证项金额之和与选中的请款单总金额之和有出入，是否继续':
    '汇款凭证项金额之和与选中的请款单总金额之和有出入，是否继续',
  '未选择，或选择多个': '未选择，或选择多个',
  '批量创建汇款凭证项/工资单': '批量创建汇款凭证项 + 工资单',
  '银行手续费是负数，是否继续创建': '银行手续费是负数，是否继续创建',
  批量手动发邮件: '批量手动发邮件',
  批量下载PDF: '批量下载PDF',
  请选择需要付款的工资单: '请选择需要付款的工资单',
  是否确认批量付款: '是否确认批量付款',
  请选择需要下载PDF的请款单: '请选择需要下载PDF的请款单',
  是否确认批量下载PDF: '是否确认批量下载PDF',
  请选择需要生成PDF的工资单: '请选择需要生成PDF的工资单',
  是否确认生成PDF: '是否确认生成PDF',
  邮件发送失败: '邮件发送失败',
  请选择需要发送邮件的请款单: '请选择需要发送邮件的请款单',
  是否确认发送邮件: '是否确认发送邮件',
  服务结束月份: '服务结束月份',
  请填写服务结束月份: '请填写服务结束月份',
  服务开始月份: '服务开始月份',
  请填写服务开始月份: '请填写服务开始月份',
  请选择银行账户: '请选择银行账户',
  未创建汇款凭证项: '未创建汇款凭证项',
  请选择未创建汇款凭证项: '请选择未创建汇款凭证项',
  全部数据: '全部数据',
  请款单管理: '请款单管理',
  修改请款单: '修改请款单',
  查看备注: '查看备注',
  备注记录: '备注记录',
  修改请款单管理费成功: '修改请款单管理费成功',
  请款单批量导入: '请款单批量导入',
  可选顾问: '可选顾问',
  未找到相关顾问: '未找到相关顾问',
  查找顾问: '查找顾问',
  合同日期: '合同日期',
  银行转账支付方式: '银行转账支付方式',
  查询顾问: '查询顾问',
  取消成功: '取消成功',
  批量创建请款单: '批量创建请款单',
  请款单批量创建: '请款单批量创建',
  批量手动发送: '批量手动发送',
  批量创建成功: '批量创建成功',
  批量生成PDF成功: '批量生成PDF成功',
  批量发邮件成功: '批量发邮件成功',
  批量手动发邮件成功: '批量手动发邮件成功',
  '无可用合同，请添加': '无可用合同，请添加',
  '有误，请修改上传导入文件': '有误，请修改上传导入文件',
  '当前已有请款单,是否创建另一个': '当前已有请款单,是否创建另一个',
  '请款起始日期不能为空，请修改上传导入文件': '请款起始日期不能为空，请修改上传导入文件',
  '服务月份不能为空，请修改上传导入文件': '服务月份不能为空，请修改上传导入文件',
  '该顾问无当前合同，请单独创建请款单': '该顾问无当前合同，请单独创建请款单',
  是否确认批量0付款: '是否确认批量0付款',
  请选择需要下载PDF的工资单: '请选择需要下载PDF的工资单',
  工资单已经付款: '工资单已经付款',
  是否确认将符合条件的工资单生成PDF: '是否确认将符合条件的工资单生成PDF',
  请选择需要发送邮件的工资单: '请选择需要发送邮件的工资单',
  请填写汇款凭证流水日期: '请填写汇款凭证流水日期',
  汇款凭证流水日期: '汇款凭证流水日期',
  工资单管理: '工资单管理',
  请款月份: '请款月份',
  请款金额: '请款金额',
  是否0付款: '是否0付款',
  请选择付款月份: '请选择付款月份',
  批量发邮件: '批量发邮件',
  批量0付款: '批量0付款',
  批量付款: '批量付款',
  地址管理: '地址管理',
  修改地址: '修改地址',
  紧急联系人管理: '紧急联系人管理',
  新建紧急联系人: '新建紧急联系人',
  请填输入记录: '请填输入记录',
  请填输入描述: '请填输入描述',
  修改紧急联系人: '修改紧急联系人',
  记录管理: '记录管理',
  新建记录: '新建记录',
  修改记录: '修改记录',
  个人参考号管理: '个人参考号管理',
  新建个人参考号: '新建个人参考号',
  社保菜单: '社保菜单',
  入职日期: '入职日期',
  起保月份: '起保月份',
  缴纳城市: '缴纳城市',
  户籍类型: '户籍类型',
  外地农村: '外地农村',
  外地城镇: '外地城镇',
  本地农村: '本地农村',
  本地城镇: '本地城镇',
  缴费项目: '缴费项目',
  请选择户籍类型: '请选择户籍类型',
  重要提示阅读记录: '重要提示阅读记录',
  待办事项管理: '待办事项管理',
  待办事项批量导入: '待办事项批量导入',
  间隔天数: '间隔天数',
  待办事项批量导入预览: '待办事项批量导入预览',
  model标记: 'model标记',
  请填写model_flag: '请填写model_flag',
  model名称: 'model名称',
  请填写model名称: '请填写model名称',
  请选择审批状态: '请选择审批状态',
  请选择是否可用: '请选择是否可用',
  微信小程序数据变更: '微信小程序数据变更',
  是否审批通过: '是否审批通过',
  是否审批拒绝: '是否审批拒绝',
  增扣提醒管理: '增扣提醒管理',
  请选择顾问: '请选择顾问',
  增扣提醒批量导入: '增扣提醒批量导入',
  待增扣提醒批量导入预览: '待增扣提醒批量导入预览',
  时间范围: '时间范围',
  额外项异常数据: '额外项异常数据',
  错误类型: '错误类型',
  额外项数值: '额外项数值',
  额外项名称: '额外项名称',
  额外项隶属顾问: '额外项隶属顾问',
  额外项隶属工号: '额外项隶属工号',
  权限管理: '权限管理',
  菜单管理: '菜单管理',
  新建菜单: '新建菜单',
  表格斑马纹: '表格斑马纹',
  开: '开',
  关: '关',
  PC端: 'PC端',
  菜单: '菜单',
  树: '树',
  路由: '路由',
  隶属组织列表: '隶属组织列表',
  平台: '平台',
  索引: '索引',
  是否菜单: '是否菜单',
  对应接口: '对应接口',
  目标组织: '目标组织',
  请选择目标组织: '请选择目标组织',
  菜单名称: '菜单名称',
  请填写菜单名称: '请填写菜单名称',
  请选择状态: '请选择状态',
  请填写路由: '请填写路由',
  请选择平台: '请选择平台',
  菜单索引: '菜单索引',
  请填写菜单索引: '请填写菜单索引',
  父级菜单: '父级菜单',
  请选择父级菜单: '请选择父级菜单',
  是否为菜单: '是否为菜单',
  隶属组织: '隶属组织',
  请选择组织: '请选择组织',
  接口: '接口',
  菜单名称必须填写: '菜单名称必须填写',
  新增菜单成功: '新增菜单成功',
  修改菜单成功: '修改菜单成功',
  选择多个API接口: '选择多个API接口',
  接口名称: '接口名称',
  请填写接口名称: '请填写接口名称',
  绝对路径url: '绝对路径url',
  接口管理: '接口管理',
  未绑定菜单的接口: '未绑定菜单的接口',
  路径: '路径',
  是否需要授权: '是否需要授权',
  请选择是否授权: '请选择是否授权',
  请填写接口路径: '请填写接口路径',
  绝对路径: '绝对路径',
  请输入接口名称: '请输入接口名称',
  请输入绝对路径: '请输入绝对路径',
  接口列表: '接口列表',
  接口对应菜单: '接口对应菜单',
  新建接口: '新建接口',
  设为需要授权: '设为需要授权',
  是否设为需要授权: '是否设为需要授权',
  设为无需授权: '设为无需授权',
  是否设为无需授权: '是否设为无需授权',
  导入接口: '导入接口',
  导入api接口json文件: '导入api接口json文件',
  接口JSON: '接口JSON',
  请填写账号名称: '请填写账号名称',
  导入成功: '导入成功',
  角色描述: '角色描述',
  请填写角色描述: '请填写角色描述',
  请填写角色标识: '请填写角色标识',
  角色标识: '角色标识',
  角色名称: '角色名称',
  请填写角色名称: '请填写角色名称',
  角色名称必须填写: '角色名称必须填写',
  角色管理: '角色管理',
  新建角色: '新建角色',
  修改用户: '修改用户',
  修改角色: '修改角色',
  修改权限: '修改权限',
  是否确认删除: '是否确认删除',
  请输入角色名称: '请输入角色名称',
  请选择隶属组织: '请选择隶属组织',
  创建角色: '创建角色',
  删除角色成功: '删除角色成功',
  恢复角色成功: '恢复角色成功',
  新增角色成功: '新增角色成功',
  修改角色成功: '修改角色成功',
  修改用户成功: '修改用户成功',
  修改用户角色: '修改用户角色',
  加载中: '加载中',
  CC公司权限: 'CC公司权限',
  CC用户: 'CC用户',
  请选择CC用户: '请选择CC用户',
  用户名称: '用户名称',
  请填写用户名称: '请填写用户名称',
  创建: '创建',
  创建CC用户权限: '创建CC用户权限',
  修改CC用户权限: '修改CC用户权限',
  人员组织: '人员组织',
  用户管理: '用户管理',
  部门管理: '部门管理',
  角色: '角色',
  请选择角色: '请选择角色',
  手机号: '手机号',
  请填手机号: '请填手机号',
  真实姓名: '真实姓名',
  请填真实姓名: '请填真实姓名',
  请填职务: '请填职务',
  电子邮箱: '电子邮箱',
  请填电子邮箱: '请填电子邮箱',
  所属部门: '所属部门',
  请输入手机号: '请输入手机号',
  请选择所属部门: '请选择所属部门',
  请输入真实姓名: '请输入真实姓名',
  用户ID: '用户ID',
  '手机号（用户名）': '手机号（用户名）',
  '请填写手机号（用户名）': '请填写手机号（用户名）',
  部门: '部门',
  新建部门: '新建部门',
  部门名称: '部门名称',
  请填写部门名称: '请填写部门名称',
  隶属部门: '隶属部门',
  客服: '客服',
  客服人员: '客服人员',
  客服名称: '客服名称',
  请填写客服名称: '请填写客服名称',
  模板邮箱: '模板邮箱',
  领班: '领班',
  离线: '离线',
  上班: '上班',
  下班: '下班',
  转客服: '转客服',
  接入: '接入',
  分配: '分配',
  发送图片: '发送图片',
  发送链接: '发送链接',
  请输入: '请输入',
  发送: '发送',
  未接入: '未接入',
  我的: '我的',
  所有: '所有',
  历史: '历史',
  机器人: '机器人',
  无更多信息加载: '无更多信息加载',
  不可发送空白消息: '不可发送空白消息',
  当前不可发送链接: '当前不可发送链接',
  当前账号无客服权限: '当前账号无客服权限',
  链接发送: '链接发送',
  链接: '链接',
  请填写链接内容: '请填写链接内容',
  当前不可发送图片: '当前不可发送图片',
  图片发送: '图片发送',
  确认关闭会话: '确认关闭会话',
  在线客服: '在线客服',
  选择客服: '选择客服',
  离线客服: '离线客服',
  转给: '转给',
  转客服成功: '转客服成功',
  只可选择一位客服进行转接: '只可选择一位客服进行转接',
  只可选择一位客服进行接入: '只可选择一位客服进行接入',
  请选择一位客服进行转接: '请选择一位客服进行转接',
  分配客服接入: '分配客服接入',
  分配成功: '分配成功',
  确认接入会话: '确认接入会话',
  接入成功: '接入成功',
  '请写http/https开头的链接': '请写http/https开头的链接',
  '当前处在‘下班’状态，请上班后操作。': '当前处在‘下班’状态，请上班后操作。',
  '点击左侧会话,开启聊天': '点击左侧会话,开启聊天',
  你是想问如下问题吗: '你是想问如下问题吗',

  '未找到相关问题，请直接转人工': '未找到相关问题，请直接转人工',
  已读: '已读',
  未读: '未读',
  点击加载更多: '点击加载更多',
  请选择称谓: '请选择称谓',
  名: '名', // first_name
  请填写名: '请填写名',
  姓: '姓', // last_name
  请填写姓: '请填写姓',
  请填写其他名字: '请填写其他名字',
  请选择国籍: '请选择国籍',
  请填写手机: '请填写手机',
  请填写个人邮箱: '请填写个人邮箱',
  请填写工作邮箱: '请填写工作邮箱',
  请填写微信账号: '请填写微信账号',
  请填写WhatsApp账号: '请填写WhatsApp账号',
  WhatsApp账号: 'WhatsApp账号',
  请填写QQ账号: '请填写QQ账号',
  请选择个人类别: '请选择个人类别',
  请选择家庭地址: '请选择家庭地址',
  请选择工作地址: '请选择工作地址',
  请选择个人状态: '请选择个人状态',
  其他姓名: '其他姓名',
  '请填写Skype ID': '请填写Skype ID',
  选择隶属顾问: '选择隶属顾问',
  账户名: '账户名',
  项目主键: '项目主键',
  选择顾问: '选择顾问',
  在线: '在线',
  无推送: '无推送',
  客服未接入消息: '客服未接入消息',
  未读消息: '未读消息',
  新增顾问: '新增顾问',
  模糊搜索: '模糊搜索',
  全部公司: '全部公司',
  修改密码: '修改密码',
  切换用户: '切换用户',
  退出登录: '退出登录',
  有: '有',
  '条 额外项异常数据': '条 额外项异常数据',
  账号密码登录: '账号密码登录',
  账户或密码错误: '账户或密码错误',
  请输入账户: '请输入账户',
  请输入密码: '请输入密码',
  自动登录: '自动登录',
  请输入正确的手机号: '请输入正确的手机号',
  请输入验证码: '请输入验证码',
  请输入帐户名或邮箱地址: '请输入帐户名或邮箱地址',
  欢迎: '欢迎',
  欢迎回来: '欢迎回来',
  登录过期: '登录过期',
  请重新登录: '请重新登录',
  服务器端错误: '服务器端错误',
  选择银行: '选择银行',
  手动发送成功: '手动发送成功',
  '没有CC银行账户，请添加': '没有CC银行账户，请添加',
  默认只能上传第一个文件: '默认只能上传第一个文件',
  数据ID: '数据ID',
  请填写数据ID: '请填写数据ID',
  修改标签: '修改标签',

  版本号: '版本号',
  顾问公司账号: '顾问公司账号',
  新建顾问公司账号: '新建顾问公司账号',
  修改顾问公司账号: '修改顾问公司账号',
  登录账号: '登录账号',
  请填写登录账号: '请填写登录账号',
  账号状态: '账号状态',
  账号提示邮件: '账号提示邮件',
  是否重置: '是否重置',
  账号密码: '账号密码',
  是否恢复账号可用: '是否恢复账号可用',
  禁用账号: '禁用账号',
  最低管理费: '最低管理费',
  请填写最低管理费: '请填写最低管理费',
  上传本地合同: '上传本地合同',
  本地合同附件: '本地合同附件',
  阅读时间: '阅读时间',
  导出: '导出',
  '当前已有一个工资类型请款单,是否创建另一个?': '当前已有一个工资类型请款单,是否创建另一个?',
  '工资类型请款单已有2个，不可继续创建': '工资类型请款单已有2个，不可继续创建',
  '已过期超60天，不可请款': '已过期超60天，不可请款',
  添加: '添加',
  网址: '网址',
  请填写网址: '请填写网址',
};
